import Page from './Page';

const routes = [
    {
        id: 'help',
        name: 'Help',
        path: '/help',
        Component: Page,
    },
];

export default routes;
