import AddIcon from '@mui/icons-material/Add';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import CustomDialog from 'components/Dialog';
import WriterSections from 'components/AiWriter/WriterSections';
import SectionSources from 'components/AiWriter/SectionSources';
import SelectTemplate from 'components/AiWriter/SelectTemplate';
import CustomSnackbar from 'components/Snackbar';
import {Section} from 'models/section';
import {Template} from 'models/template';
import {useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, ContentState, Modifier} from 'draft-js';
import Fab from '@mui/material/Fab';
import {requests} from 'requests';
import {Operation} from 'models/operation';
import type {CustomDialogProps} from 'components/Dialog';
import type {CustomSnackbarProps} from 'components/Snackbar';
import ApplyWritingRule from 'components/AiWriter/ApplyWritingRule';
import {writingRules} from 'requests/writingRules';
import {WritingRule} from 'models/writingRule';
import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface WriterSectionsRef {
    getAllSections: () => Section[];
}

const Page = () => {
    const navigate = useNavigate();
    const params = useParams();

    // identify the window height
    const windowHeight = window.innerHeight;
    // identify the window width
    const windowWidth = window.innerWidth;

    // Get the template id from the params if available
    const templateId = params['id'] || '';

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    // State to store the currently selected section
    const [selectedSection, setSelectedSection] = useState(new Section({}));

    // Template
    const [selectedTemplate, setSelectedTemplate] = useState(
        new Template({
            name: '',
            operations: [],
        }),
    );

    // Editor state
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const writerSectionsRef = useRef<WriterSectionsRef | null>(null);

    // Handle selected template
    const handleSelectedTemplate = (template: Template) => {
        setSelectedTemplate(template);
    };

    // Handle selected section
    const handleSelectedSection = (section: Section) => {
        setSelectedSection(section);
    };

    // Handle editor state change
    const handleEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };

    const htmlToDraftWrapper = (html: string) => {
        const blocksFromHtml = htmlToDraft(html);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const newEditorState = EditorState.createWithContent(newContentState);
        return newEditorState;
    };

    const getHtmlSelection = (): {selection: any; selectedText: string} | undefined => {
        // Get the current selection from the editor state
        const selection = editorState.getSelection();

        // Check if the selection is collapsed (i.e., no content is selected)
        if (selection.isCollapsed()) {
            setSnackBarState({
                open: true,
                message: 'Please select some text to perform this action',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Extract the selected blocks based on the selection
        const contentState = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const endKey = selection.getEndKey();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const blockMap = contentState.getBlockMap();
        const selectedBlocks = blockMap
            .toSeq()
            .skipUntil((_, k) => k === startKey)
            .takeUntil((_, k) => k === endKey)
            .concat([[endKey, blockMap.get(endKey)]])
            .map((block, blockKey) => {
                let text = block.getText();
                if (blockKey === startKey) {
                    text = text.slice(startOffset);
                }
                if (blockKey === endKey) {
                    text = text.slice(0, endOffset);
                }
                return block.set('text', text);
            });

        // Convert selected blocks to ContentState and then to raw HTML
        const selectedContentState = contentState.set('blockMap', selectedBlocks) as ContentState;
        const selectedText = stateToHTML(selectedContentState);

        return {selection, selectedText};
    };

    const updateHtmlSelection = (newHtml: string) => {
        const selectionAndText = getHtmlSelection();

        if (!selectionAndText) {
            setSnackBarState({
                open: true,
                message: 'Please select some text to perform this action',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        const selection = selectionAndText.selection;

        // Convert the raw HTML to a ContentState
        const contentStateFromHTML = stateFromHTML(newHtml);

        // Merge this content with the existing content
        const currentContent = editorState.getCurrentContent();
        const contentWithReplacement = Modifier.replaceWithFragment(currentContent, selection, contentStateFromHTML.getBlockMap());

        // Create a new EditorState with the updated content
        const newEditorState = EditorState.push(editorState, contentWithReplacement, 'replace-text');

        // Set the updated EditorState
        setEditorState(newEditorState);
    };

    const handleApplyWritingRule = async (writingRule: WritingRule) => {
        // If writing rules is empty, show a message
        if (!writingRule._id) {
            setSnackBarState({
                open: true,
                message: 'Please select a writing rule to apply',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Get the current selection and selected text
        const selectionAndText = getHtmlSelection();

        if (!selectionAndText) {
            return;
        }

        const selectedText = selectionAndText.selectedText;

        // Apply the writing rule to the selected text
        try {
            setDialogState({
                open: true,
                title: 'Applying Writing Rule',
                message: 'Please wait while we apply the writing rule to your text...',
            });

            const res = await writingRule.apply(selectedText);

            updateHtmlSelection(res.result);

            setDialogState({
                open: false,
            });
        } catch (error) {
            console.error('Failed to apply writing rule:', error);

            setDialogState({
                open: true,
                title: 'Error',
                message: "We're sorry, something went wrong while applying the writing rule. Please try again later.",
                button1Text: 'Close',
                button1Action: () => {
                    setDialogState({...dialogState, open: false});
                },
            });
        }
    };

    const manipulateText = async (action: string) => {
        // Get the current selection and selected text
        const selectionAndText = getHtmlSelection();

        if (!selectionAndText) {
            return;
        }

        const {selectedText} = selectionAndText;
        let title, message, requestMethod;

        switch (action) {
            case 'paraphrase':
                title = 'Paraphrasing';
                message = 'Please wait while we paraphrase your text...';
                requestMethod = requests.pseudo.paraphrase;
                break;
            case 'shorten':
                title = 'Shortening';
                message = 'Please wait while we shorten your text...';
                requestMethod = requests.pseudo.shorten;
                break;
            case 'lengthen':
                title = 'Lengthening';
                message = 'Please wait while we lengthen your text...';
                requestMethod = requests.pseudo.lengthen;
                break;
            default:
                throw new Error('Invalid action');
        }

        try {
            setDialogState({
                open: true,
                title,
                message,
            });

            const res = await requestMethod(selectedText);

            updateHtmlSelection(res.result);

            setDialogState({
                open: false,
            });
        } catch (error) {
            console.error(`Failed to ${action}:`, error);

            setDialogState({
                open: true,
                title: 'Error',
                message: `We're sorry, something went wrong while ${action}ing. Please try again later.`,
                button1Text: 'Close',
                button1Action: () => {
                    setDialogState({...dialogState, open: false});
                },
            });
        }
    };

    // Generate
    const generateContent = async () => {
        // Get all the sections
        const allSections = writerSectionsRef.current?.getAllSections();

        // check if there are any sections
        if (!allSections || allSections.length === 0) {
            setSnackBarState({
                open: true,
                message: 'Please add at least one section to the template',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Validate that each section has at least one source and not empty
        const invalidSections = allSections?.filter(section => {
            // Check if section is a placeholder
            if (section.isPlaceholder) {
                return false;
            }

            if (section.sources.length === 0) {
                return true;
            }

            if (section.sources[0].value === '') {
                return true;
            }
        });

        if (invalidSections && invalidSections.length > 0) {
            setSnackBarState({
                open: true,
                message: `Please add at least one source to section "${invalidSections[0].name}"`,
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Generate the content
        setDialogState({
            open: true,
            title: 'Generating Content',
            message: 'Please wait while we generate your content...',
        });

        try {
            const templateToGenerate = selectedTemplate;

            // Update the template with the sections
            templateToGenerate.operations = allSections?.map(section => {
                return new Operation({
                    op: section.getID(),
                    name: section.name,
                    sources: section.sources,
                });
            });

            type ResultItem = string[] | string;

            const extractStrings = (item: ResultItem): string => {
                if (Array.isArray(item)) {
                    return item.join('{newline}');
                }
                return item;
            };

            // Generate the content
            const res = await requests.templates.run(templateToGenerate);

            // Extract the strings from the nested arrays
            let extractedStrings = res.result.map(extractStrings).join('{newline}');

            // Replace one or more <br />, <br>, <br/> with single <br />
            // extractedStrings = extractedStrings.replace(/<br\s*\/?>+/g, '<br />');

            // Replace {newline} with \n\n
            extractedStrings = extractedStrings.replace(/{newline}/g, "<p style='border: 0px solid red; margin: 0px; padding: 0px;'></p>");

            const newEditorState = htmlToDraftWrapper(extractedStrings);

            // Set this new editor state
            setEditorState(newEditorState);

            setDialogState({
                open: false,
            });
        } catch (error) {
            console.error('Failed to Generate:', error);

            setDialogState({
                open: true,
                title: 'Error',
                message: "We're sorry, something went wrong while generating your content. Please try again later.",
                button1Text: 'Close',
                button1Action: () => {
                    setDialogState({...dialogState, open: false});
                },
            });
        }
    };

    const handleError = (error: Error) => {
        setSnackBarState({
            open: true,
            message: error.message,
            severity: 'error',
        });
    };

    return (
        // Main grid container
        <Grid container spacing={0} sx={{flexDirection: 'column'}}>
            <Grid item container flexGrow={1} flex={1}>
                {/* Sidebar grid item */}
                <Grid
                    item
                    md={2}
                    sx={{
                        // Add a slight border to the right of the sidebar
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <SelectTemplate selectedTemplateId={templateId} onTemplateSelect={handleSelectedTemplate} onError={() => {}} />

                    <WriterSections
                        template={selectedTemplate}
                        onSectionSelect={section => {
                            handleSelectedSection(section);
                        }}
                        ref={writerSectionsRef}
                    />
                </Grid>
                {/* Sidebar grid item */}
                {selectedSection && (
                    <Grid
                        item
                        md={3}
                        sx={{
                            // Add a slight border to the right of the sidebar
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <SectionSources section={selectedSection} />
                    </Grid>
                )}
                {/* Main content grid item */}
                <Grid
                    item
                    md={selectedSection ? 7 : 10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '.contentEditorWrapper': {
                            width: '100%',
                            // temporary fix for the editor height
                            height: windowWidth < 1380 ? `${windowHeight - 275}px` : `${windowHeight - 240}px`,
                        },
                        '.contentEditor': {
                            paddingX: '10px',
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            backgroundColor: 'white',
                        },
                        '.contentEditorToolbar': {
                            padding: '10px',
                            paddingTop: '15px',
                        },
                    }}>
                    {/* Show 2 buttons: Paraphrase & shorten */}

                    <Grid container spacing={2} sx={{width: '100%'}} p={1} alignItems={'center'} justifyContent={'flex-end'}>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                sx={{width: '100%'}}
                                onClick={() => {
                                    manipulateText('paraphrase');
                                }}>
                                Paraphrase
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{width: '100%'}}
                                onClick={() => {
                                    manipulateText('shorten');
                                }}>
                                Shorten
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{width: '100%'}}
                                onClick={() => {
                                    manipulateText('lengthen');
                                }}>
                                Lengthen
                            </Button>
                        </Grid>

                        <Grid item xs={4}>
                            <ApplyWritingRule handleApplyWritingRule={handleApplyWritingRule} />
                        </Grid>
                    </Grid>

                    <Editor
                        editorState={editorState}
                        toolbarClassName="contentEditorToolbar"
                        wrapperClassName="contentEditorWrapper"
                        editorClassName="contentEditor"
                        onEditorStateChange={newEditorState => handleEditorStateChange(newEditorState)}
                        handlePastedText={() => false}
                    />
                </Grid>
            </Grid>

            <Fab
                color="primary"
                aria-label="Generate"
                sx={{
                    position: 'fixed',
                    bottom: '16px',
                    right: '16px',
                }}
                onClick={generateContent}
                variant="extended">
                <HistoryEdu sx={{mr: 1}} />
                Generate
            </Fab>

            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Grid>
    );
};

export default Page;
