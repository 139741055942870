import React from 'react';
import {Avatar, Grid, Button, List, ListItemAvatar, ListItemText, IconButton} from '@mui/material';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewDay from '@mui/icons-material/ViewDay';
import {grey} from '@mui/material/colors';
import {Section} from 'models/section';
import FolderIcon from '@mui/icons-material/Folder';
import {reorder} from 'utils/helpers';
import {ListItemButton} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

interface SidebarProps {
    sections: Section[];
    setSections: (sections: Section[]) => void;
    selectedSection: Section;
    handleAddSection: () => void;
    handleDeleteSection: (sectionToDelete: number) => void;
    setSelectedSection: (section: Section) => void;
    saveTemplate: () => void;
    deleteTemplate: () => void;
    createMode: boolean;
    gridRef: React.RefObject<HTMLDivElement>;
}

const TemplateEditorSidebar: React.FC<SidebarProps> = ({
    sections,
    setSections,
    selectedSection,
    handleAddSection,
    handleDeleteSection,
    setSelectedSection,
    saveTemplate,
    deleteTemplate,
    createMode,
    gridRef,
}) => {
    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const reorderedSections = reorder(sections, result.source.index, result.destination.index);

        setSections(reorderedSections);
    };

    return (
        <Grid
            item
            md={2}
            sx={{
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                display: 'flex',
                flexDirection: 'column',
            }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sectionsList">
                    {provided => (
                        <Grid item xs={12} sx={{p: 0, overflowY: 'scroll', flex: '1 1 auto !important', height: '0px'}} ref={gridRef} {...provided.droppableProps}>
                            <List ref={provided.innerRef}>
                                {sections.map((item: Section, index: number) => (
                                    <Draggable key={item.getKey()} draggableId={String(item.getKey())} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItemButton
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => setSelectedSection(item)}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    background: snapshot.isDragging ? grey[300] : 'transparent',
                                                }}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            background: selectedSection.getKey() === item.getKey() ? grey[900] : grey[500],
                                                        }}>
                                                        <ViewDay />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={item.name}
                                                    sx={{
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        color: selectedSection.getKey() === item.getKey() ? 'black' : 'grey.800',
                                                    }}
                                                />
                                                <Tooltip title="Delete Template">
                                                    <IconButton edge="end" onClick={() => handleDeleteSection(item.getKey())}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemButton>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>

            <Grid
                item
                xs={12}
                flex={'0 !important'}
                sx={{
                    mx: 2,
                    mb: 2,
                }}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddSection}
                    sx={{
                        width: '100%',
                        bgcolor: 'grey.300',
                        color: 'black',
                        '&:hover': {
                            bgcolor: 'grey.300',
                        },
                    }}>
                    Add new section
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                display={'flex'}
                flex={'0 !important'}
                gap={1}
                sx={{
                    mx: 2,
                    mb: 2,
                }}>
                <Button
                    onClick={saveTemplate}
                    variant="contained"
                    startIcon={<FolderIcon />}
                    sx={{
                        flexGrow: 1,
                    }}>
                    Save
                </Button>
                <Button color="error" variant="contained" onClick={deleteTemplate} disabled={createMode}>
                    <DeleteIcon />
                </Button>
            </Grid>
        </Grid>
    );
};

export default TemplateEditorSidebar;
