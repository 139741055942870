import Page from './Page';

const routes = [
    {
        id: 'accept-invite-new-user',
        name: 'Accept Invite & Create Account',
        path: '/accept-invite-and-create',
        Component: Page,
    },
];

export default routes;
