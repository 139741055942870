import Page from './Page';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const routes = [
    {
        id: 'users',
        name: 'Users',
        path: '/users',
        showInSidebar: false,
        pageSidebarPosition: 2,
        icon: PeopleAltIcon,
        Component: Page,
        tags: ['user-config'],
    },
];

export default routes;
