import Page from './Page';
import {AuthProvider, loginLoader, protectedLoader} from 'utils/auth';

const routes = [
    {
        id: 'login',
        name: 'Login',
        path: '/login',
        loader: loginLoader,
        Component: Page,
    },
];

export default routes;
