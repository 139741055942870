import {Paper, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import PageSidebar from 'components/PageSidebar';
import {Link} from 'react-router-dom';
import {getRoutesByTags} from 'router/routes';

const Page = () => {
    return (
        <Grid container spacing={2} sx={{p: 2}}>
            <Grid item xs={2}>
                <PageSidebar routes={getRoutesByTags(['user-config'])} />
            </Grid>
            <Grid item xs={10} height={'100%'}>
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                    }}>
                    <Typography variant="h6">Settings</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Page;
