import {RouterProvider} from 'react-router-dom';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import router from 'router';
import {grey} from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            main: '#212121',
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#212121',
            // light: will be calculated from palette.secondary.main,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: will be calculated to contrast with palette.secondary.main
        },
    },
    components: {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: grey[800],
                },
            },
        },
    },
    typography: {
        body1: {
            color: grey[800],
        },
        body2: {
            color: grey[800],
        },
        h1: {
            color: grey[800],
        },
        h2: {
            color: grey[800],
        },
        h3: {
            color: grey[800],
        },
        h4: {
            color: grey[800],
        },
        h5: {
            color: grey[800],
        },
        h6: {
            color: grey[800],
        },
        subtitle1: {
            color: grey[800],
        },
        subtitle2: {
            color: grey[800],
        },
        button: {
            color: grey[800],
        },
        caption: {
            color: grey[800],
        },
        overline: {
            color: grey[800],
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
        </ThemeProvider>
    );
}

export default App;
