import {Button, IconButton, InputAdornment, Paper, Snackbar, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import PageSidebar from 'components/PageSidebar';
import SideBar from 'components/Sidebar';
import {getRouteById, getRoutesByTags} from 'router/routes';
import {User} from 'models/user';
import CustomSnackbar from 'components/Snackbar';
import type {CustomSnackbarProps} from 'components/Snackbar';
import {useState} from 'react';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

const Page = () => {
    const user = User.getInstance();
    const navigate = useNavigate();

    const [disableUserActions, setDisableUserActions] = useState(false);

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // State variables
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);

    // Event handler for Save button
    const handleSaveClick = async () => {
        setDisableUserActions(true);
        try {
            // return if both fields are empty
            if (!newPassword && !confirmPassword) {
                throw new Error('Please enter a new password');
            }

            if (newPassword !== confirmPassword) {
                throw new Error('Passwords do not match');
            } else if (newPassword.length < 8) {
                throw new Error('Password must be at least 8 characters');
            }

            await user.updatePassword(newPassword);
            await user.fetchSelf().then(() => {
                // get my-templates route by id
                const route = getRouteById('my-templates');
                if (route) {
                    // navigate to the my-templates page
                    navigate(route.path);
                }
            });

            setSnackBarState({
                open: true,
                message: 'Password updated successfully. Redirecting to the home page...',
                severity: 'success',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
        } catch (error) {
            setSnackBarState({
                open: true,
                message: error.message,
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
        } finally {
            setDisableUserActions(false); // Re-enable the button whether or not an error occurred
        }
    };

    return (
        <Grid container spacing={2} sx={{p: 2}}>
            <Grid item xs={12} height={'100%'}>
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                    }}>
                    <Typography variant="h6">Set a new password</Typography>
                    <Typography variant="body2">Before you can continue, you must set a new password.</Typography>
                    {/* Profile form */}
                    <Grid container spacing={2} pt={2}>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-basic"
                                label="New Password"
                                variant="standard"
                                fullWidth
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="standard-basic"
                                label="Confirm Password"
                                variant="standard"
                                fullWidth
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                type={showConfirmPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowConfirmPassword} edge="end">
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt={3} textAlign="right">
                            <LoadingButton variant="contained" color="primary" onClick={handleSaveClick} loading={disableUserActions}>
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Grid>
    );
};

export default Page;
