import Page from './Page';

const routes = [
    {
        id: 'auto-login',
        name: 'Auto login',
        path: '/auto-login',
        Component: Page,
    },
];

export default routes;
