import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {ThemeProvider, createTheme} from '@mui/material/styles';

import {AuthProvider} from 'utils/auth';
import {getRouteById} from 'router/routes';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import {useState} from 'react';
import {getFormattedPageTitle} from 'utils/helpers';
import {IconButton} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {requests} from 'requests';
import { validateEmail } from 'utils/validations';

const defaultTheme = createTheme();

export default function SignUp() {
    document.title = getFormattedPageTitle('Sign Up');
    const navigate = useNavigate();

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    const [disableUserActions, setDisableUserActions] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const name = data.get('name');
        const email = data.get('email');
        const password = data.get('password');
        const confirmPassword = data.get('confirmPassword');

        if (password !== confirmPassword) {
            setSnackBarState({
                open: true,
                message: 'Passwords do not match',
                severity: 'error',
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // validate email
        if (!validateEmail(email as string)) {
            setSnackBarState({
                open: true,
                message: 'Invalid email',
                severity: 'error',
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Sign in and redirect to the proper destination if successful.
        try {
            setDisableUserActions(true);
            await requests.user.signup(name as string, email as string, password as string);
        } catch (error) {
            setSnackBarState({
                open: true,
                message: 'Something went wrong',
                severity: 'error',
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        } finally {
            setDisableUserActions(false);
        }
        setDialogState({
            open: true,
            title: 'Sign Up Successful',
            message: 'Please check your email for a verification link',
            button1Text: 'Close',
            button1Action: () => {
                setDialogState({
                    open: false,
                });
                navigate('/login');
            }
        });
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <img src={require('assets/images/logo.svg').default} alt="logo" style={{width: '150px'}} />
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}, alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField margin="normal" required fullWidth id="name" label="Name" name="name" autoComplete="name" autoFocus />
                        <TextField margin="normal" required fullWidth id="email" label="Email" name="email" autoComplete="email" type="email" />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            id="password"
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <ThemeProvider theme={defaultTheme}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </ThemeProvider>
                                ),
                            }}
                        />
                        {/* Confirm password */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            id="confirmPassword"
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <ThemeProvider theme={defaultTheme}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </ThemeProvider>
                                ),
                            }}
                        />
                        <LoadingButton type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} disabled={disableUserActions} loading={disableUserActions}>
                            Sign Up
                        </LoadingButton>
                        <Grid container>
                            <Grid item xs>
                                <Link
                                    variant="body2"
                                    onClick={() => {
                                        const route = getRouteById('forgot-password');
                                        if (route) {
                                            navigate(route.path);
                                        }
                                    }}>
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid
                                item
                                xs
                                sx={{
                                    textAlign: 'right',
                                }}>
                                <Link
                                    sx={{cursor: 'pointer'}}
                                    variant="body2"
                                    onClick={() => {
                                        const route = getRouteById('login');
                                        if (route) {
                                            navigate(route.path);
                                        }
                                    }}>
                                    Already have an account? Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Container>
    );
}
