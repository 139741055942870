import Page from "./Page";
import Widgets from '@mui/icons-material/Widgets';
import AccountCircle from '@mui/icons-material/AccountCircle';

const routes = [
    {
        id: 'profile',
        name: 'Profile',
        path: '/profile',
        showInSidebar: false,
        pageSidebarPosition: 1,
        icon: AccountCircle,
        Component: Page,
        tags: ['user-config'],
    },
]

export default routes;