import React, {useState, useRef} from 'react';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Source} from 'models/source';
import {Section} from 'models/section';
import {Grid, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Typography} from '@mui/material';

interface SelectSectionProps {
    section: Section;
    onError?: (error: Error) => void;
}

const SectionSources: React.FC<SelectSectionProps> = ({section, onError}) => {
    const [sources, setSources] = useState<Source[]>(section.sources);
    const gridRef = useRef<HTMLDivElement>(null);

    // Add a new source
    const addNewSource = () => {
        const newSource = new Source({key: '', value: ''});
        setSources(prevSources => [...prevSources, newSource]);
        // Update the section
        section.addSource(newSource);
    };

    // Delete a source
    const deleteSource = (sourceKey: number) => {
        // Remove the source from the section
        section.removeSource(sourceKey);
        // Remove the source from the based on the source.key
        const newSources = sources.filter(source => source.getKey() !== sourceKey);
        setSources(newSources);
    };

    // handle change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        // Find the source in the sources array
        const source = sources.find(s => s.getKey() === name);
        // If the source is found, update the value
        if (source) {
            source.value = value;
        }
        // Update the sources array
        setSources([...sources]);
    };

    // re-render on section change
    React.useEffect(() => {
        setSources(section.sources);
    }, [section]);

    // When section is empty show a message
    if (!section.getID()) {
        return (
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Typography variant="body1">Please select a section to add sources</Typography>
            </Grid>
        );
    }

    return (
        <Grid item xs={12} sx={{p: 0, overflowY: 'scroll', flex: '1 1 auto !important', height: '0px'}} ref={gridRef}>
            <Typography variant="body1" px={2} pt={2}>
                {section.name}
            </Typography>

            <hr></hr>
            {section.isPlaceholder && (
                <Grid
                    item
                    xs={12}
                    sx={{
                        maxWidth: '100%',
                        overflowX: 'scroll',
                        px: 2,
                        mt: 0,
                    }}
                    dangerouslySetInnerHTML={{__html: section.example || ''}}></Grid>
            )}

            {!section.isPlaceholder && (
                <List>
                    {sources.map(source => (
                        <ListItem key={source.getKey()}>
                            <FormControl fullWidth>
                                <TextField
                                    id={source.getKey()}
                                    label="Enter text or url"
                                    variant="outlined"
                                    value={source.value}
                                    name={source.getKey()}
                                    onChange={handleChange}
                                    multiline
                                />
                            </FormControl>
                            {/* Delete button */}
                            <IconButton edge="end" aria-label="delete" onClick={() => deleteSource(source.getKey())} disabled={sources.length === 1}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
            )}
            {!section.isPlaceholder && (
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={addNewSource}
                    sx={{
                        mx: 2,
                        mb: 5,
                    }}>
                    Input
                </Button>
            )}
        </Grid>
    );
};

export default SectionSources;
