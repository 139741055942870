import {Grid, Paper, Typography} from '@mui/material';

const WelcomeMessage = () => {
    return (
        <Grid item xs={8} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flexDirection: 'column'}}>
            <Paper
                variant="outlined"
                sx={{
                    p: 3,
                    width: '100%', // Set width to 100% to use the full space of the grid
                }}>
                <Typography variant="h5" component="div" sx={{fontSize: '1.5rem', fontWeight: 'bold', mb: 2}}>
                    👋 Hi! Welcome to the Inkform Template Builder.
                </Typography>
                <Typography variant="body1" sx={{mb: 2}}>
                    This is where you can build your own custom writing automations. Some examples of what you can build include:
                </Typography>
                <Typography component="ul" sx={{ '& > li': { mb: 2 } }}>
                    <Typography component="li" variant="body1">
                        <span role="img" aria-label="newsletter">
                            ☕
                        </span>{' '}
                        Multi-section newsletters
                    </Typography>
                    <Typography component="li" variant="body1">
                        <span role="img" aria-label="email">
                            📧
                        </span>{' '}
                        Multi-email drip campaigns
                    </Typography>
                    <Typography component="li" variant="body1">
                        <span role="img" aria-label="case study">
                            🔍
                        </span>{' '}
                        Multi-step case studies
                    </Typography>
                    <Typography component="li" variant="body1">
                        <span role="img" aria-label="SEO">
                            📚
                        </span>{' '}
                        Multi-section SEO articles
                    </Typography>
                    <Typography component="li" variant="body1">
                        <span role="img" aria-label="website">
                            🌐
                        </span>{' '}
                        Multi-page websites/landing pages
                    </Typography>
                    <Typography component="li" variant="body1">
                        <span role="img" aria-label="social media">
                            📢
                        </span>{' '}
                        Multi-channel social media/ads
                    </Typography>
                </Typography>
                <Typography variant="body1" sx={{mt: 2}}>
                    To get started, just click on <b>+ Add New Section</b> in the lower left-hand corner.
                </Typography>
                <Typography variant="body1" sx={{mt: 1}}>
                    Or click <b>Copy</b> on one of the example templates in <b>My Templates</b> for inspiration.
                </Typography>
            </Paper>
        </Grid>
    );
};

export default WelcomeMessage;
