import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from 'components/Header';
import Topbar from 'components/Topbar';
import {useCustomScript} from 'hooks/injectScript';
import {User} from 'models/user';
import {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {getRouteById, getRouteByPath} from 'router/routes';
import {getFormattedPageTitle} from 'utils/helpers';

export default function ClippedDrawer() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    // Keep the drawer open on mdUp unless the user explicitly closes it
    // const [isDrawerOpen, setDrawerOpen] = useState(isMdUp);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const user = User.getInstance();

    const location = useLocation();
    const currentRoute = getRouteByPath(location.pathname);

    // set the page title based on the current route. eg: "Dashboard | App Name", "Login | App Name"
    document.title = getFormattedPageTitle(currentRoute?.name || 'Dashboard');

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    // Add onboarding guide here
    useCustomScript(
        `
            <!-- Usetiful script start -->
            (function (w, d, s) {
                var a = d.getElementsByTagName('head')[0];
                var r = d.createElement('script');
                r.async = 1;
                r.src = s;
                r.setAttribute('id', 'usetifulScript');
                r.dataset.token = "d8d93942f87369fded3e0eb30da288a1";
                                    a.appendChild(r);
            })(window, document, "https://www.usetiful.com/dist/usetiful.js");
    
            <!-- Usetiful script end -->
        `,
        {
            parent: 'body', // Insert into body
            position: 'end', // Insert at the end of the body
        },
        // cleanup function
        () => {
            // remove element by id usetifulScript
            const element = document.getElementById('usetifulScript');
            if (element) {
                element.remove();
            }

            // remove button with class uf-visible
            setTimeout(() => {
                const button = document.querySelector('.uf-visible');
                if (button) {
                    button.remove();
                }
            }, 500);
        },
    );

    // check if users password is changed
    function checkPasswordChange() {
        if (!user.isPasswordChanged() && currentRoute?.id != 'force-change-password') {
            // get force-change-password
            const forceChangePasswordRoute = getRouteById('force-change-password');
            // redirect to force-change-password
            navigate(forceChangePasswordRoute?.path || '/force-change-password');
        }
    }

    useEffect(() => {
        checkPasswordChange();
    }, [location]);

    return (
        <Box
            sx={{
                display: 'flex',
                // set the height of the container so that it fills the screen
                position: 'fixed',
                flexDirection: 'column',
                height: '100%',
                width: '100%',

                // backgroundColor: "#A9C9FF",
                // backgroundImage: "linear-gradient(241deg, #A9C9FF 0%, #FFBBEC 100%)",

                background: '#8BC6EC',
                backgroundImage: 'linear-gradient(135deg, #8BC6EC 0%, #8BC6EC 100%)',

                // background: "radial-gradient(circle at 50% 50%, #d8b5ff 20%, #0000 80%),conic-gradient(from 45deg at 50% 50%, #1eae98 0, #d8b5ff 25%, #1eae98 50%, #d8b5ff 75%, #1eae98 100%)",

                // background: "radial-gradient(circle at 50% 50%, rgb(179 255 248) 20%, transparent 80%), conic-gradient(from 45deg at 50% 50%, rgb(159,232,253) 0%, rgb(17 175 255) 25%, rgb(159,232,253) 50%, rgb(162 237 255) 75%, rgb(159,232,253) 100%);"
            }}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    // background: "radial-gradient(circle at 50% 50%, #d8b5ff 20%, #0000 80%), conic-gradient(from 45deg at 50% 50%, #1eae98 0, #d8b5ff 25%, #1eae98 50%, #d8b5ff 75%, #1eae98 100%)",
                    boxShadow: 'rgb(0 0 0) 0px 2px 8px 0px',
                }}>
                <Header toggleDrawer={toggleDrawer} />
                <Toolbar />
                <Topbar />
            </Box>

            {/* <SideBar isDrawerOpen={isDrawerOpen} /> */}

            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flex: 1,
                    background: 'rgb(255 255 255 / 85%);',
                    overflowY: 'scroll',
                }}>
                <Outlet />
            </Box>
        </Box>
    );
}
