import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {ThemeProvider, createTheme} from '@mui/material/styles';

import {AuthProvider} from 'utils/auth';
import {useState} from 'react';
import {requests} from 'requests';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import {getRouteById} from 'router/routes';
import {getFormattedPageTitle} from 'utils/helpers';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

interface FormErrors {
    password?: string;
    confirmPassword?: string;
}

export default function SignIn() {
    document.title = getFormattedPageTitle('Reset Password');
    const navigate = useNavigate();

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    // get the token and email from the url params
    const urlParams = new URLSearchParams(window.location.search);

    const [disableUserActions, setDisableUserActions] = useState(false);

    // if the token or email is not present, redirect to the login page
    if (!urlParams.has('token') || !urlParams.has('email')) {
        navigate('/login');
    }

    // get the token and email from the url params
    const token = urlParams.get('token');
    const email = urlParams.get('email');

    // state for the form
    const [formState, setFormState] = useState({
        password: '',
        confirmPassword: '',
    });

    // State for password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // State for form validation errors
    const [errors, setErrors] = useState<FormErrors>({});

    // Function to validate form fields
    const validateForm = () => {
        let tempErrors = {} as FormErrors;
        tempErrors.password = formState.password ? '' : 'This field is required.';
        tempErrors.confirmPassword = formState.confirmPassword ? '' : 'This field is required.';
        if (formState.password !== formState.confirmPassword) {
            tempErrors.confirmPassword = 'Passwords do not match.';
        }
        setErrors({...tempErrors});
        return Object.values(tempErrors).every(x => x === '');
    };

    // Handler for form submission
    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                setDisableUserActions(true);
                let res = await requests.user.resetPassword(
                    {
                        password: formState.password,
                    },
                    token as string,
                );

                setDialogState({
                    open: true,
                    title: 'Password Reset Successful',
                    message: 'Your password has been reset successfully. Please login to continue.',
                    button1Text: 'Login',
                    button1Action: () => {
                        const route = getRouteById('login');
                        if (route) {
                            navigate(route.path);
                        }
                    },
                    button1Color: 'primary',
                    button1Variant: 'contained',
                });
            } catch (error) {
                console.log(error);
                setSnackBarState({
                    open: true,
                    message: 'Error resetting password. Please try again later.',
                    severity: 'error',
                    autoHideDuration: 5000,
                    onClose: () => {
                        setSnackBarState({...snackBarState, open: false});
                    },
                });
            } finally {
                setDisableUserActions(false);
            }
        }
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <img src={require('assets/images/logo.svg').default} alt="logo" style={{width: '150px'}} />
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}, alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h5">Reset Password</Typography>
                    <Typography variant="body2" sx={{textAlign: 'center'}}>
                        Please enter a new password for your account.
                    </Typography>
                    <Box sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            InputProps={{
                                readOnly: true,
                            }}
                            value={email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            autoComplete="current-password"
                            error={!!errors.password}
                            helperText={errors.password}
                            value={formState.password}
                            onChange={e => setFormState({...formState, password: e.target.value})}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <ThemeProvider theme={createTheme()}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </ThemeProvider>
                                ),
                            }}
                        />
                        {/* Confirm Password */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            id="confirmPassword"
                            autoComplete="current-password"
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            value={formState.confirmPassword}
                            onChange={e => setFormState({...formState, confirmPassword: e.target.value})}
                            type={showConfirmPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <ThemeProvider theme={createTheme()}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            onMouseDown={e => e.preventDefault()}
                                            edge="end">
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </ThemeProvider>
                                ),
                            }}
                        />
                        <LoadingButton type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} onClick={handleSubmit} loading={disableUserActions}>
                            SUBMIT
                        </LoadingButton>
                        <Grid container>
                            <Grid item xs>
                                <Link
                                    variant="body2"
                                    onClick={() => {
                                        const route = getRouteById('forgot-password');
                                        if (route) {
                                            navigate(route.path);
                                        }
                                    }}>
                                    Go back
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Container>
    );
}
