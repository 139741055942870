import {Alert, AlertTitle, CircularProgress, Paper, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import PageSidebar from 'components/PageSidebar';
import {Link} from 'react-router-dom';
import {getRoutesByTags} from 'router/routes';
import {Button} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import {useEffect, useState} from 'react';
import {requests} from 'requests';
import {User} from 'models/user';

const Page = () => {
    const [loading, setLoading] = useState(true);
    const [customerPortal, setCustomerPortal] = useState(null);
    const user = User.getInstance();

    useEffect(() => {
        let timeoutId: number | null = null; // Declare the variable with type annotation

        const fetchCustomerPortal = async () => {
            setLoading(true);
            try {
                let res = await requests.user.getCustomerPortal();
                setCustomerPortal(res);

                // Redirect to billing portal in 3 seconds
                timeoutId = window.setTimeout(() => {
                    // Use window.setTimeout for clarity and correct typing
                    window.location.href = res.session.url;
                }, 5000);

                console.log(res);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };

        // Fetch customer portal if user is admin
        if (user?.isCurrentOrgAdmin()) {
            fetchCustomerPortal();
        }

        // If user is not admin, stop loading
        if (!user?.isCurrentOrgAdmin()) {
            setLoading(false);
        }

        return () => {
            if (timeoutId !== null) {
                clearTimeout(timeoutId); // Clear the timeout if the component is unmounted
            }
        };
    }, []);

    return (
        <Grid container spacing={2} sx={{p: 2}}>
            <Grid item xs={2}>
                <PageSidebar routes={getRoutesByTags(['user-config'])} />
            </Grid>
            <Grid item xs={10} height={'100%'}>
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                    }}>
                    <Typography variant="h6">Billing</Typography>

                    {loading && (
                        <Grid p={2} justifyContent={'center'} container>
                            <CircularProgress />
                        </Grid>
                    )}

                    {!loading && !customerPortal && !user?.isCurrentOrgAdmin() && (
                        <Alert severity="warning" sx={{mt: 2}}>
                            <Typography variant="body2">You are not an admin of this organization. Only admins can access the billing portal.</Typography>
                        </Alert>
                    )}

                    {!loading && !customerPortal && user?.isCurrentOrgAdmin() && (
                        <Alert severity="error" sx={{mt: 2}}>
                            <AlertTitle>Error fetching billing information</AlertTitle>
                            You may not have access to the billing portal. If the issue persists, please contact{' '}
                            <a href="mailto:support@inkform.ai">support@inkform.ai</a> for more information.
                        </Alert>
                    )}

                    {!loading && customerPortal && (
                        <>
                            <Typography variant="body1" mt={2}>
                                Redirecting you to the billing portal. If you are not redirected automatically, please click the button below.
                            </Typography>

                            <Button
                                variant="contained"
                                sx={{mt: 4}}
                                endIcon={<EastIcon />}
                                onClick={() => {
                                    window.location.href = customerPortal?.session.url;
                                }}>
                                Go to Billing Portal
                            </Button>

                            <Typography variant="body1" mt={4}>
                                If you have any question related to billing, you can send us an email at <Link to="mailto:support@inkform.ai">support@inkform.ai</Link>.
                            </Typography>
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Page;
