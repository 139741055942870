import Page from "./Page";
import NewspaperIcon from '@mui/icons-material/Newspaper';

const routes = [
    {
        id: 'my-templates',
        name: 'My Templates',
        path: '/my-templates',
        showInSidebar: true,
        sidebarPosition: 1,
        icon: NewspaperIcon,
        Component: Page,
    },
]

export default routes;