import {Link, Paper, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';

const Page = () => {
    return (
        <Grid container spacing={2} sx={{p: 2, overflowY: 'scroll'}}>
            <Grid item xs={12}>
                <Typography variant="h5" mb={2}>
                    Help Section
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Paper sx={{p: 2, height: '100%'}}>
                            <Typography variant="h6" gutterBottom>
                                Watch this quick explainer video to get started
                            </Typography>
                            <iframe
                                width="100%"
                                height="500px"
                                src="https://www.youtube.com/embed/UvUi7W5ldv4"
                                title="How to Use Inkmail to Build and Automatically Write Newsletters"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            {/* Timestamps */}
                            <Typography variant="body1" gutterBottom>
                                <p>
                                    <b>00:06{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=6" target="_blank" sx={{}}>
                                        Intro: What is Inkmail for?
                                    </Link>
                                </p>

                                <p>
                                    <b>00:26{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=26" target="_blank" sx={{}}>
                                        How to create an account and log in
                                    </Link>
                                </p>

                                <p>
                                    <b>00:41{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=41" target="_blank" sx={{}}>
                                        How to build a new newsletter template
                                    </Link>
                                </p>

                                <p>
                                    <b>01:23{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=83" target="_blank" sx={{}}>
                                        How to create your first newsletter section
                                    </Link>
                                </p>

                                <p>
                                    <b>02:59{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=179" target="_blank" sx={{}}>
                                        How to test a newsletter section to see if it works
                                    </Link>
                                </p>

                                <p>
                                    <b>04:08{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=248" target="_blank" sx={{}}>
                                        How to create a placeholder text section
                                    </Link>
                                </p>

                                <p>
                                    <b>04:54{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=294" target="_blank" sx={{}}>
                                        How to create a news section that can open a URL
                                    </Link>
                                </p>

                                <p>
                                    <b>07:29{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=449" target="_blank" sx={{}}>
                                        How to create an editorial section (that works like ChatGPT)
                                    </Link>
                                </p>

                                <p>
                                    <b>09:52{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=592" target="_blank" sx={{}}>
                                        Getting creative with section-building
                                    </Link>
                                </p>

                                <p>
                                    <b>10:59{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=659" target="_blank" sx={{}}>
                                        Using a saved template to auto-write drafts
                                    </Link>
                                </p>

                                <p>
                                    <b>13:13{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=793" target="_blank" sx={{}}>
                                        How to edit your draft in the output window
                                    </Link>
                                </p>

                                <p>
                                    <b>14:43{': '}</b>
                                    <Link href="https://youtu.be/UvUi7W5ldv4?t=883" target="_blank" sx={{}}>
                                        How to review previously generated drafts
                                    </Link>
                                </p>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Paper sx={{p: 2, height: '100%'}}>
                            <Typography variant="h6" gutterBottom>
                                Need more help or want to give feedback?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <p>
                                    Read this{' '}
                                    <Link href="https://www.inkform.ai/blog-posts/how-to-get-started-with-inkmail-the-step-by-step-tutorial" target="_blank">
                                        helpful how-to guide
                                    </Link>
                                </p>
                                <p>
                                    Check out the{' '}
                                    <Link href="http://www.inkform.ai/help-center-categories/frequently-asked-questions" target="_blank">
                                        Inkmail FAQ
                                    </Link>
                                </p>
                                <p>
                                    Fill out this{' '}
                                    <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdgwsdWtlVhXhS6rlmVIiFRtLblV86uFHRgFGcIpm-4adP7zg/viewform" target="_blank">
                                        feedback form
                                    </Link>
                                </p>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Page;
