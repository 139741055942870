import {requests} from 'requests';
import {v4 as uuidv4} from 'uuid';

export class Source {
    key: any;
    value: string;

    constructor(data: any) {
        this.key = data.key;
        this.value = data.value;

        // generate a temporary ID
        if (!this.key) {
            this.generateKey();
        }
    }

    getKey() {
        if (!this.key) {
            this.generateKey();
        }
        return this.key;
    }

    private generateKey() {
        this.key = uuidv4();
    }
}
