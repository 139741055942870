import {Source} from 'models/source';
import {requests} from 'requests';
import {v4 as uuidv4} from 'uuid';

export class Section {
    _id: any;
    key: any;
    name: string;
    owner: string;
    example: string;
    prompt: string;
    dateCreated: Date;
    needsUpdate: boolean;
    sampleInput: string;
    preview: string;
    isPlaceholder: boolean;
    sources: Source[];

    constructor(data: any) {
        this._id = data._id;
        this.key = data.key;
        this.name = data.name;
        this.owner = data.owner;
        this.example = data.example;
        this.prompt = data.prompt;
        this.dateCreated = data.dateCreated;
        this.needsUpdate = data.needsUpdate;
        this.sampleInput = data.sampleInput;
        this.preview = data.preview;
        this.isPlaceholder = data?.isPlaceholder || false;
        this.sources = data.sources ? data.sources.map((source: any) => new Source(source)) : [];

        // generate a temporary ID
        if (!this.key) {
            this.generateKey();
        }

        // check if sources are empty
        if (!this.sources.length) {
            this.sources.push(new Source({value: ''}));
        }
    }

    getID() {
        return this._id;
    }

    setID(id: any) {
        this._id = id;
    }

    getKey() {
        if (!this.key) {
            this.generateKey();
        }
        return this.key;
    }

    runBody() {
        return {
            op: this._id,
            // value of all sources
            elements: this.sources.map(source => source.value),
        };
    }

    private generateKey() {
        this.key = uuidv4();
    }

    async createInApi() {
        try {
            const response: any = await requests.sections.create(this);
            this._id = response._id;
            this.needsUpdate = false;
            return this;
        } catch (e: any) {
            throw new Error(e);
        }
    }

    async updateInApi() {
        try {
            const response: any = await requests.sections.update(this);
            this.needsUpdate = false;
            return this;
        } catch (e: any) {
            throw new Error(e);
        }
    }

    createBody() {
        return {
            name: this.name,
            example: this.example,
            prompt: this.prompt,
            isPlaceholder: this.isPlaceholder,
        };
    }

    updateBody() {
        return {
            name: this.name,
            example: this.example,
            prompt: this.prompt,
            isPlaceholder: this.isPlaceholder,
        };
    }

    // creates a copy of the section with a new key but the same ID
    copyWithID() {
        const copy = new Section(this);
        copy.generateKey();
        return copy;
    }

    // creates a copy of the section with a new key and a blank ID
    copy() {
        const copy = new Section(this);
        copy.generateKey();
        copy.setID(null);
        return copy;
    }

    addSource(source: Source) {
        this.sources.push(source);
    }
    getSource(sourceKey: any) {
        return this.sources.find(source => source.key === sourceKey);
    }

    getSources() {
        return this.sources;
    }

    updateSource(sourceKey: any, value: string) {
        const source = this.getSource(sourceKey);
        if (source) {
            source.value = value;
        }
    }

    removeSource(sourceKey: any) {
        this.sources = this.sources.filter(source => source.key !== sourceKey);
    }

    async delete() {
        if (!this._id) {
            return;
        }

        try {
            await requests.sections.delete(this._id);
        } catch (e: any) {
            throw new Error(e);
        }
    }
}
