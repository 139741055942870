import {Paper} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {getRouteById, getRouteByPath, protectedRoutes} from 'router/routes';

export interface PageSidebarProps {
    routes: any;
}

const PageSidebar: React.FC<PageSidebarProps> = ({routes}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentRoute = getRouteByPath(location.pathname);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const findSidebarPosition = (route): number => {
        if (route && route.sidebarPosition) {
            return route.sidebarPosition;
        } else if (route && route.parentId) {
            const parentRoute = getRouteById(route.parentId);
            return findSidebarPosition(parentRoute);
        } else {
            return 0;
        }
    };

    useEffect(() => {
        const position = findSidebarPosition(currentRoute);
        setSelectedTabIndex(position - 1 || 0);
    }, [currentRoute]);

    return (
        <Paper variant="outlined" square={false}>
            <List sx={{py: 0}}>
                {/* list routes that have showInSidebar set to true and sort according to pageSidebarPosition. If no pageSidebarPosition is passed, show them at the end */}
                {routes
                    .sort((a, b) => (a.pageSidebarPosition || 999) - (b.pageSidebarPosition || 999))
                    .map(route => (
                        <ListItem key={route.id} disablePadding selected={route.id === currentRoute?.id}>
                            {/* Link to route.path */}
                            <ListItemButton onClick={() => navigate(route.path)}>
                                <ListItemIcon>{React.createElement(route.icon)}</ListItemIcon>
                                <ListItemText primary={route.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </Paper>
    );
};

export default PageSidebar;
