import Page from "./Page";
import Balance from '@mui/icons-material/Balance';

const routes = [
    {
        id: 'writing-rules',
        name: 'Writing Rules',
        path: '/writing-rules',
        showInSidebar: true,
        sidebarPosition: 3,
        icon: Balance,
        Component: Page,
    },
]

export default routes;