import {Alert, CircularProgress, LinearProgress, Paper, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import PageSidebar from 'components/PageSidebar';
import {Link} from 'react-router-dom';
import {getRoutesByTags} from 'router/routes';
import {User} from 'models/user';
import {useEffect, useState} from 'react';
import {formatNumber} from 'utils/helpers';
import LinearProgressWithLabel from 'components/Indicator/Progress';

const Page = () => {
    const user = User.getInstance();
    const [loading, setLoading] = useState(true);
    const [rerender, setRerender] = useState(0);

    useEffect(() => {
        // Fetch user data
        user.fetchSelf().then(() => {
            // Rerender
            setRerender(rerender + 1);
            // Stop loading
            setLoading(false);
        });
    }, []);

    return (
        <Grid container spacing={2} sx={{p: 2}}>
            <Grid item xs={2}>
                <PageSidebar routes={getRoutesByTags(['user-config'])} />
            </Grid>
            <Grid item xs={10} height={'100%'}>
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                    }}>
                    <Typography variant="h6">Usage</Typography>

                    {user?.getSelectedOrganization()?.isOnTrialPlan() && (
                        <Alert severity="warning" sx={{mt: 2, mb: 2, fontSize: '1.2rem',border: '1px solid rgba(0, 0, 0, 0.10)'}} icon={false}>
                            You are currently on the <b>Trial Plan</b> with a limit of {formatNumber(user?.getSelectedOrganization()?.getTrialTokens(), {})} tokens.
                            Your upcoming plan is <b>{user?.getSelectedOrganization()?.getPlanName()}</b>. To manage your plan, please navigate to the Billing page.
                        </Alert>
                    )}

                    {!user?.getSelectedOrganization()?.isOnTrialPlan() && (
                        <Alert severity="success" sx={{mt: 2, mb: 2, fontSize: '1.2rem',border: '1px solid rgba(0, 0, 0, 0.10)'}} icon={false}>
                            You are on the <b>{user?.getSelectedOrganization()?.getPlanName()}</b> plan. To manage your plan, please navigate to the Billing page.
                        </Alert>
                    )}

                    {/* 4 boxes to show usage for tokens, templates, writing rules, and users */}
                    {loading && (
                        <Grid p={2} justifyContent={'center'} container>
                            <CircularProgress />
                        </Grid>
                    )}

                    {!loading && (
                        <Grid container spacing={2} sx={{mt: 0}}>
                            <Grid item xs={12} md={6} lg={3}>
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        p: 0,
                                        pt: 2,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}>
                                    <Typography variant="h4" mt={2}>
                                        {' '}
                                        {formatNumber(user?.getSelectedOrganization()?.getUsage().usedTokens, {}) || 0} /{' '}
                                        {user?.getSelectedOrganization()?.isOnTrialPlan()
                                            ? formatNumber(user?.getSelectedOrganization()?.getTrialTokens(), {})
                                            : formatNumber(user?.getSelectedOrganization()?.getUsage().maxTokens, {}) || 0}
                                    </Typography>

                                    <Typography variant="h6" mb={2}>
                                        Tokens
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={
                                            (user?.getSelectedOrganization()?.getUsage().usedTokens / user?.getSelectedOrganization()?.getUsage().maxTokens) * 100 || 0
                                        }
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        p: 0,
                                        pt: 2,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <Typography variant="h4" mt={2}>
                                        {' '}
                                        {formatNumber(user?.getSelectedOrganization()?.getUsage().usedTemplates, {}) || 0} /{' '}
                                        {formatNumber(user?.getSelectedOrganization()?.getUsage().maxTemplates, {}) || 0}
                                    </Typography>
                                    <Typography variant="h6" mb={2}>
                                        Templates
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={
                                            (user?.getSelectedOrganization()?.getUsage().usedTemplates / user?.getSelectedOrganization()?.getUsage().maxTemplates) *
                                                100 || 0
                                        }
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        p: 0,
                                        pt: 2,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <Typography variant="h4" mt={2}>
                                        {' '}
                                        {formatNumber(user?.getSelectedOrganization()?.getUsage().usedRules, {}) || 0} /{' '}
                                        {formatNumber(user?.getSelectedOrganization()?.getUsage().maxRules, {}) || 0}
                                    </Typography>
                                    <Typography variant="h6" mb={2}>
                                        Writing Rules
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={(user?.getSelectedOrganization()?.getUsage().usedRules / user?.getSelectedOrganization()?.getUsage().maxRules) * 100 || 0}
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        p: 0,
                                        pt: 2,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <Typography variant="h4" mt={2}>
                                        {' '}
                                        {formatNumber(user?.getSelectedOrganization()?.getUsage().usedSeats, {}) || 0} /{' '}
                                        {formatNumber(user?.getSelectedOrganization()?.getUsage().maxSeats, {}) || 0}
                                    </Typography>
                                    <Typography variant="h6" mb={2}>
                                        Seats
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={(user?.getSelectedOrganization()?.getUsage().usedSeats / user?.getSelectedOrganization()?.getUsage().maxSeats) * 100 || 0}
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Page;
