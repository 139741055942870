import Edit from '@mui/icons-material/Edit';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import SectionForm from 'components/TemplateEditor/SectionForm';
import TemplateEditorSidebar from 'components/TemplateEditor/TemplateEditorSidebar';
import WelcomeMessage from 'components/TemplateEditor/WelcomeMessage';
import {Section} from 'models/section';
import {Template} from 'models/template';
import {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {requests} from 'requests';
import {getRouteById, replaceRouteParams} from 'router/routes';

const Page = () => {
    const navigate = useNavigate();
    const params = useParams();

    // check if the params has :id. If not, we are in create mode
    const createMode = !params.hasOwnProperty('id');

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    // Template
    const [template, setTemplate] = useState(
        new Template({
            _id: params.id ? params.id : null,
            name: '',
            operations: [],
        }),
    );

    const [templateNameError, setTemplateNameError] = useState('');

    // State to store the list of sections
    const [sections, setSections] = useState([] as Section[]);

    // originalSections is used to store the original sections so that we can compare the changes
    const [originalSections, setOriginalSections] = useState([] as Section[]);

    // State to store the currently selected section
    const [selectedSection, setSelectedSection] = useState(sections[0]);

    const gridRef = useRef<HTMLDivElement | null>(null);

    // Function to add a new section
    const handleAddSection = () => {
        // Validate existing sections and store the errors in an array
        if (!validateSections()) {
            return;
        }

        const newSection = new Section({
            name: 'New Section',
            needsUpdate: false,
        });

        setSections([...sections, newSection]);

        // Select the newly added item
        setSelectedSection(newSection);

        // Scroll to the bottom
        if (gridRef.current) {
            gridRef.current.scrollTop = gridRef.current.scrollHeight;
        }
    };

    // Validate all the sections
    const validateSections = () => {
        const errors = sections.map(item => validateSection(item)).filter(item => item !== null);
        if (errors.length > 0) {
            setSnackBarState({
                open: true,
                message: errors[0] || '',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return false;
        }
        return true;
    };

    // Validate a section
    const validateSection = (section: Section) => {
        if (!section.name) {
            return 'Section name cannot be empty';
        }
        if (!section.prompt && !section.isPlaceholder) {
            return `Directions for the section "${section.name}" cannot be empty`;
        }
        if (!section.example) {
            return `Desired output for the section "${section.name}" cannot be empty`;
        }
        return null;
    };

    // Function to handle changes made to the section content
    const handleContentChange = (field: keyof Section, value: any) => {
        if (selectedSection && selectedSection[field] !== value) {
            const updatedSection = new Section({
                // Copy all the existing properties
                ...selectedSection,
                // Update the field that was changed
                [field]: value,
                // Set the needsUpdate flag to true if the section is already created
                // No need to set needsUpdate to true if the section is not created yet
                needsUpdate: selectedSection.getID() ? true : false,
            });

            // Update the section in the sections array
            setSections(sections.map(item => (item.getKey() === selectedSection.getKey() ? updatedSection : item)));

            setSelectedSection(updatedSection);
        }
    };

    // Function to initiate the delete section flow
    const handleDeleteSection = (sectionToDelete: number) => {
        if (template.isExample) {
            setSnackBarState({
                open: true,
                message: 'Cannot delete sections from Example Templates. Make a copy of an Example Template from My Templates to customize it.',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        setDialogState({
            open: true,
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this section? This action cannot be undone.',
            onClose: () => {},
            button1Text: 'Cancel',
            button1Action: () => {
                setDialogState({
                    ...dialogState,
                    open: false,
                });
            },
            button1Color: 'primary',
            button1Variant: 'contained',
            button2Text: 'Confirm',
            button2Action: () => {
                if (sectionToDelete) {
                    const filteredItems = sections.filter(item => item.getKey() !== sectionToDelete);
                    setSections(filteredItems);
                    setSelectedSection(filteredItems[0] || null);
                }
                setDialogState({
                    ...dialogState,
                    open: false,
                });
            },
            button2Color: 'error',
            button2Variant: 'contained',
            disableEscapeKeyDown: true,
        });
    };

    // Function to delete the template
    const deleteTemplate = async () => {
        if (createMode) {
            return;
        }

        if (template.isExample) {
            setSnackBarState({
                open: true,
                message: 'Cannot delete Example Templates. Make a copy of an Example Template from My Templates to customize it.',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        setDialogState({
            open: true,
            title: 'Confirm Deletion',
            message: 'Are you sure you want to delete this template? This action cannot be undone.',
            onClose: () => {},
            button1Text: 'Cancel',
            button1Action: () => {
                setDialogState({
                    ...dialogState,
                    open: false,
                });
            },
            button1Color: 'primary',
            button1Variant: 'contained',
            button2Text: 'Confirm',
            button2Action: async () => {
                try {
                    setDialogState({
                        open: true,
                        title: 'Deleting',
                        message: 'Deleting template',
                        onClose: () => {},
                        disableEscapeKeyDown: true,
                    });

                    // Delete all the sections in parallel
                    const deletePromises = sections.map(item => item.delete());
                    await Promise.all(deletePromises);

                    const response = await requests.templates.delete(template.getID());

                    // Show the dialog and redirect to the my templates page
                    setDialogState({
                        open: true,
                        title: 'Success',
                        message: 'Template deleted successfully',
                        onClose: () => {},
                        button1Text: 'Go to My Templates',
                        button1Action: () => {
                            const route = getRouteById('my-templates');
                            if (route) {
                                navigate(route.path);
                            }
                        },
                        button1Color: 'primary',
                        button1Variant: 'contained',
                    });
                } catch (error: any) {
                    setDialogState({
                        open: false,
                    });
                    setSnackBarState({
                        open: true,
                        message: error.message.includes('section')
                            ? `Error deleting section "${error.item.name}". Please try again later.`
                            : 'Error deleting template. Please try again later.',
                        severity: 'error',
                        autoHideDuration: 5000,
                        onClose: () => {
                            setSnackBarState({...snackBarState, open: false});
                        },
                    });
                    return;
                }
            },
            button2Color: 'error',
            button2Variant: 'contained',
            disableEscapeKeyDown: true,
        });
    };

    // Test the section
    const testSection = async () => {
        // Validate the section
        const error = validateSection(selectedSection);
        if (error) {
            setSnackBarState({
                open: true,
                message: error,
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Validate the sample input
        if (!selectedSection.sampleInput) {
            setSnackBarState({
                open: true,
                message: 'Sample input cannot be empty',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Open the dialog
        setDialogState({
            open: true,
            title: 'Generating Output',
            message: 'Generating output for the section',
            onClose: () => {},
            disableEscapeKeyDown: true,
        });

        // Generate the output
        try {
            const response = await requests.sections.testUnsaved(selectedSection.example, selectedSection.prompt, selectedSection.sampleInput);
            // Update the preview
            handleContentChange('preview', response.result);
            setDialogState({
                open: false,
            });
        } catch (error) {
            setDialogState({
                open: false,
            });
            setSnackBarState({
                open: true,
                message: 'Error generating output. Please try again later.',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
        }
    };

    // Save the template
    const saveTemplate = async () => {
        if (template.isExample) {
            setSnackBarState({
                open: true,
                message: 'Cannot update Example Template. Make a copy of the Example Template from My Templates to customize it.',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // Validate the template name
        if (!template.name) {
            setTemplateNameError('Template name cannot be empty');
            return;
        }

        // Validate the sections
        if (!validateSections()) {
            return;
        }

        setDialogState({
            open: true,
            title: 'Saving',
            message: 'Saving template details',
            onClose: () => {},
            disableEscapeKeyDown: true,
        });

        try {
            // Delete the originalSections that are not in the sections array
            const sectionsToDelete = originalSections.filter(item => !sections.find(section => section.getKey() === item.getKey()));

            // 1. Delete the sections in parallel
            const deletePromises = sectionsToDelete.map(item => item.delete());
            await Promise.all(deletePromises);
        } catch (error) {
            setDialogState({
                open: false,
            });
            setSnackBarState({
                open: true,
                message: `Error in operation. Please try again later.`,
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        try {
            // 2. Create each section that is not created yet in parallel
            const createPromises = sections.filter(item => !item.getID()).map(item => item.createInApi());
            await Promise.all(createPromises);
        } catch (error) {
            setDialogState({
                open: false,
            });

            setSnackBarState({
                open: true,
                message: 'Error creating section. Please try again later.',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });

            return;
        }

        try {
            // 3. Update each section in parallel, that has needsUpdate = true and has an ID
            const updatePromises = sections.filter(item => item.needsUpdate && item.getID()).map(item => item.updateInApi());
            await Promise.all(updatePromises);
        } catch (error) {
            setDialogState({
                open: false,
            });

            setSnackBarState({
                open: true,
                message: 'Error updating section. Please try again later.',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        // If we are in create mode, create the template
        if (createMode) {
            try {
                const newTemplate = new Template({
                    name: template.name,
                    operations: sections.map(item => ({op: item._id})),
                });

                const response: any = await newTemplate.createInApi();

                // Redirect to the template editor page
                const route = getRouteById('template-editor');
                if (route) {
                    navigate(replaceRouteParams(route.path, {id: response._id}));
                }

                setDialogState({
                    open: true,
                    title: 'Success',
                    message: 'Template created successfully',
                    onClose: () => {
                        setDialogState({
                            ...dialogState,
                            open: false,
                        });
                    },
                    button1Text: 'Go to My Templates',
                    button1Action: () => {
                        const route = getRouteById('my-templates');
                        if (route) {
                            navigate(route.path);
                        }
                    },
                    button1Color: 'primary',
                    button1Variant: 'contained',
                    button2Text: 'Continue Editing',
                    button2Action: () => {
                        setDialogState({
                            ...dialogState,
                            open: false,
                        });
                        template._id = response._id;
                        fetchTemplate();
                    },
                    button2Color: 'secondary',
                    button2Variant: 'contained',
                });
            } catch (error) {
                setDialogState({
                    open: false,
                });

                setSnackBarState({
                    open: true,
                    message: 'Error creating template. Please try again later.',
                    severity: 'error',
                    autoHideDuration: 5000,
                    onClose: () => {
                        setSnackBarState({...snackBarState, open: false});
                    },
                });
                return;
            }
        } else {
            // Update the existing template
            const existingTemplate = new Template({
                ...template,
                operations: sections.map(item => ({op: item._id})),
            });

            try {
                const response = await existingTemplate.updateInApi();

                setDialogState({
                    open: true,
                    title: 'Success',
                    message: 'Template updated successfully',
                    onClose: () => {
                        setDialogState({
                            ...dialogState,
                            open: false,
                        });
                    },
                    button1Text: 'Go to My Templates',
                    button1Action: () => {
                        const route = getRouteById('my-templates');
                        if (route) {
                            navigate(route.path);
                        }
                    },
                    button1Color: 'primary',
                    button1Variant: 'contained',
                    button2Text: 'Continue Editing',
                    button2Action: () => {
                        setDialogState({
                            ...dialogState,
                            open: false,
                        });
                        fetchTemplate();
                    },
                    button2Color: 'secondary',
                    button2Variant: 'contained',
                });
            } catch (error) {
                setDialogState({
                    open: false,
                });
                setSnackBarState({
                    open: true,
                    message: 'Error updating template. Please try again later.',
                    severity: 'error',
                    autoHideDuration: 5000,
                    onClose: () => {
                        setSnackBarState({...snackBarState, open: false});
                    },
                });
                return;
            }
        }
    };

    // Fetch the template
    const fetchTemplate = async () => {
        setDialogState({
            open: true,
            message: 'Loading template',
            onClose: () => {},
            disableEscapeKeyDown: true,
        });

        try {
            const response = await requests.templates.get(template.getID());
            setTemplate(new Template(response));

            // Fetch the sections in parallel
            const fetchSectionsPromises = response.operations.map((item: { op: string; }) => requests.sections.get(item.op));
            const fetchedSectionsResponses = await Promise.all(fetchSectionsPromises);
            const fetchedSections = fetchedSectionsResponses.map(sectionResponse => new Section(sectionResponse));

            // Set the sections
            setSections(fetchedSections);

            // Set the original sections
            setOriginalSections(fetchedSections);

            // Select the first section
            setSelectedSection(fetchedSections[0]);

            setDialogState({
                open: false,
            });
        } catch (error) {
            setDialogState({
                open: false,
                title: 'Error fetching template',
                message: 'Error fetching template. Please try again later.',
                onClose: () => {},
                disableEscapeKeyDown: true,
                // Navigate to the my templates page on click of the button
                button1Text: 'Go to My Templates',
                button1Action: () => {
                    const route = getRouteById('my-templates');
                    if (route) {
                        navigate(route.path);
                    }
                },
            });
        }
    };

    useEffect(() => {
        // if we are not in create mode, fetch the template
        if (!createMode) {
            fetchTemplate();
        }
    }, []);

    return (
        // Main grid container
        <Grid container sx={{flexDirection: 'column'}}>
            <Grid item xs={12} flex={'0 !important'}>
                <TextField
                    required
                    value={template.name}
                    onChange={e => {
                        setTemplate(
                            new Template({
                                ...template,
                                name: e.target.value,
                            }),
                        );
                        setTemplateNameError('');
                    }}
                    label="Name Writing Template"
                    error={templateNameError !== ''}
                    helperText={templateNameError}
                    variant="filled"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Edit />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item container flexGrow={1} flex={1}>
                <TemplateEditorSidebar
                    sections={sections}
                    setSections={setSections}
                    selectedSection={selectedSection}
                    handleAddSection={handleAddSection}
                    handleDeleteSection={handleDeleteSection}
                    setSelectedSection={setSelectedSection}
                    saveTemplate={saveTemplate}
                    deleteTemplate={deleteTemplate}
                    createMode={createMode}
                    gridRef={gridRef}
                />

                {/* Main content grid item */}
                <Grid
                    item
                    md={10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    {sections.length === 0 && <WelcomeMessage />}
                    {sections.length > 0 && <SectionForm selectedSection={selectedSection} handleContentChange={handleContentChange} testSection={testSection} />}
                </Grid>
            </Grid>

            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Grid>
    );
};

export default Page;
