import Page from './Page';
import {AuthProvider, loginLoader, protectedLoader} from 'utils/auth';

const routes = [
    {
        id: 'sign-up',
        name: 'Sign up',
        path: '/sign-up',
        loader: loginLoader,
        Component: Page,
    },
];

export default routes;
