import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {ThemeProvider, createTheme} from '@mui/material/styles';

import {AuthProvider} from 'utils/auth';
import {useEffect, useState} from 'react';
import {requests} from 'requests';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import {getRouteById} from 'router/routes';
import {getFormattedPageTitle} from 'utils/helpers';
import {User} from 'models/user';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {CircularProgress} from '@mui/material';

interface FormErrors {
    name?: string;
    password?: string;
    confirmPassword?: string;
}

export default function SignIn() {
    const user = User.getInstance();

    document.title = getFormattedPageTitle('Purchase Successful');
    const navigate = useNavigate();

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    // loading state
    const [loading, setLoading] = useState(true);

    // Checkout status
    const [checkoutStatus, setCheckoutStatus] = useState(false);

    // get the token and email from the url params
    const urlParams = new URLSearchParams(window.location.search);

    // if the token or email is not present, redirect to the login page
    if (!urlParams.has('checkout_session_id')) {
        navigate('/login');
    }

    // get the token and email from the url params
    const checkout_session_id = urlParams.get('checkout_session_id');

    // Handler for user login
    const getLoginToken = async (retryCount = 0) => {
        setLoading(true);
        try {
            let res = await requests.user.checkoutStatus(checkout_session_id as string);
    
            // Set the token in the user instance
            await user.loginWithToken(res.token);
    
            // Set the checkout status
            setCheckoutStatus(true);
    
            // set loading to false
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (retryCount < 5) { // Retry up to 6 times
                setTimeout(() => getLoginToken(retryCount + 1), 10000); // Retry after 10 seconds
            } else {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getLoginToken();
    }, []);

    return (
        <Container component="main">
            <CssBaseline />

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <img src={require('assets/images/logo.svg').default} alt="logo" style={{width: '150px'}} />
            </Box>

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                {loading && (
                    <>
                        <Grid p={2} justifyContent={'center'} container>
                            <CircularProgress />
                        </Grid>
                        <Grid p={2} justifyContent={'center'} container>
                            <Typography variant="h6">Please wait while check the status of your payment.</Typography>
                        </Grid>
                    </>
                )}

                {checkoutStatus && (
                    <>
                        <Typography variant="h4">🎉 Congratulations! You’re ready to start using Inkform</Typography>
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            sx={{mt: 8, mb: 2}}
                            endIcon={<ArrowRightAltIcon />}
                            onClick={() => {
                                const route = getRouteById('my-templates');
                                if (route) {
                                    navigate(route.path);
                                }
                            }}>
                            Continue
                        </Button>
                    </>
                )}

                {!loading && !checkoutStatus && (
                    <Typography variant="h6">
                        Unable to check your payment status at the moment. Please check your inbox for an email from us. If you have any questions, please reach out to us at{' '}
                        <a href="mailto:info@inkform.ai">info@inkform.ai</a>
                    </Typography>
                )}

                {/* Show a button that says continue */}
            </Box>

            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Container>
    );
}
