import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {ThemeProvider, createTheme} from '@mui/material/styles';

import {AuthProvider} from 'utils/auth';
import {useEffect, useState} from 'react';
import {requests} from 'requests';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import {getRouteById} from 'router/routes';
import { getFormattedPageTitle } from 'utils/helpers';

interface FormErrors {
    name?: string;
    password?: string;
    confirmPassword?: string;
}

export default function SignIn() {
    document.title = getFormattedPageTitle('Accept Invite');
    const navigate = useNavigate();

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    // get the token and email from the url params
    const urlParams = new URLSearchParams(window.location.search);

    // if the token or email is not present, redirect to the login page
    if (!urlParams.has('token')) {
        navigate('/login');
    }

    // get the token and email from the url params
    const token = urlParams.get('token');

    // Handler for form submission
    const handleSubmit = async () => {
        try {
            let res = await requests.user.verifyEmail(token as string);

            setDialogState({
                open: true,
                title: 'Email Verified Successfully',
                message: 'Please login to continue.',
                button1Text: 'Login',
                button1Action: () => {
                    const route = getRouteById('login');
                    if (route) {
                        navigate(route.path);
                    }
                },
                button1Color: 'primary',
                button1Variant: 'contained',
            });
        } catch (error) {
            console.log(error);
            setSnackBarState({
                open: true,
                message: 'Error verifying email',
                severity: 'error',
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
        }
    };

    useEffect(() => {
        handleSubmit();
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <img src={require('assets/images/logo.svg').default} alt="logo" style={{width: '150px'}} />
            </Box>
            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Container>
    );
}
