import {useEffect} from 'react';

const positionUfVisibleButton = () => {
    const productTour = document.querySelector('.product-tour') as HTMLElement;
    const ufButton = document.querySelector('a.uf-visible') as HTMLAnchorElement;

    if (productTour && ufButton) {
        // Show the product tour button
        productTour.style.display = 'flex';

        const productTourRect = productTour.getBoundingClientRect();

        ufButton.style.position = 'fixed';

        // reset the position of the button
        ufButton.style.left = 'initial';
        ufButton.style.bottom = 'initial';

        // Set the position of the button to the bottom right of the help button based on x and y coordinates of the help button
        ufButton.style.right = `${window.innerWidth - productTourRect.right}px`;
        ufButton.style.top = `${productTourRect.top}px`;

        // Make the entire button transparent
        ufButton.style.backgroundColor = 'transparent';
        ufButton.style.color = 'transparent';
        ufButton.style.border = 'none';
        ufButton.style.boxShadow = 'none';
    }

    // If ufButton is not visible, hide the product tour button
    if (!ufButton && productTour) {
        productTour.style.display = 'none';
    }
};

const useDisplayProductTourButton = () => {
    // Call the positionUfVisibleButton once using setTimeout to quickly position the button
    setTimeout(() => {
        positionUfVisibleButton();
    }, 100);

    useEffect(() => {

        // Call the positionUfVisibleButton every second incase the script makes changes to the DOM
        const intervalId = setInterval(() => {
            positionUfVisibleButton();
        }, 1000);

        // Cleanup
        return () => {
            // Clear the interval
            clearInterval(intervalId);
        };
    }, []);
};

export default useDisplayProductTourButton;
