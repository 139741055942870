import Page from './Page';
import Widgets from '@mui/icons-material/Widgets';

const routes = [
    {
        id: 'create-template',
        name: 'Template Editor',
        path: '/create-template',
        parentId: 'my-templates',
        exitConfirmation: "Are you sure you want to leave this page? Any unsaved changes will be lost.",
        Component: Page,
    },
    {
        id: 'template-editor',
        name: 'Template Editor',
        path: '/template-editor/:id',
        parentId: 'create-template',
        exitConfirmation: "Are you sure you want to leave this page? Any unsaved changes will be lost.",
        Component: Page,
    },
];

export default routes;
