import Page from './Page';
import PaymentIcon from '@mui/icons-material/Payment';

const routes = [
    {
        id: 'billing',
        name: 'Billing',
        path: '/billing',
        showInSidebar: false,
        pageSidebarPosition: 4,
        icon: PaymentIcon,
        Component: Page,
        tags: ['user-config'],
    },
];

export default routes;
