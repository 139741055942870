import Page from './Page';

const routes = [
    {
        id: 'checkout-status',
        name: 'Checkout status',
        path: '/checkout-status',
        Component: Page,
    },
];

export default routes;
