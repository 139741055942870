import React from 'react';
import {TextField, Typography, Grid, Button} from '@mui/material';
import {Notes} from '@mui/icons-material';
import {Section} from 'models/section';
import {Checkbox} from '@mui/material';
import {FormControlLabel} from '@mui/material';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, ContentState, Modifier, convertToRaw} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import {useState, useEffect} from 'react';
import {grey} from '@mui/material/colors';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface SectionFormProps {
    selectedSection: Section;
    handleContentChange: (field: keyof Section, value: any) => void;
    testSection: () => void;
}

const SectionForm: React.FC<SectionFormProps> = ({selectedSection, handleContentChange, testSection}) => {
    const htmlToDraftWrapper = (html: string) => {
        const blocksFromHtml = htmlToDraft(html);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const newEditorState = EditorState.createWithContent(newContentState);
        return newEditorState;
    };

    const [exampleEditorState, setExampleEditorState] = useState(htmlToDraftWrapper(selectedSection.example || ''));

    const [isExampleEditorFocused, setExampleEditorFocused] = useState(false);

    // Handle editor state change
    const handleExampleEditorStateChange = (editorState: any) => {
        setExampleEditorState(editorState);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        handleContentChange('example', html);
        console.log(html);
    };

    const handleNewLines = (text: string) => {
        let output = text;

        // Replace one or more <br />, <br>, <br/> with single <br />
        // output = output.replace(/<br\s*\/?>+/gi, '<br />');

        return output;
    };

    // Watch for changes to selectedSection.example
    useEffect(() => {
        const currentHTML = draftToHtml(convertToRaw(exampleEditorState.getCurrentContent()));
        if (currentHTML !== selectedSection.example) {
            const newEditorState = htmlToDraftWrapper(selectedSection.example || '');
            setExampleEditorState(newEditorState);
            handleExampleEditorStateChange(newEditorState);
        }
    }, [selectedSection.example, exampleEditorState]);

    return (
        <Grid item xs={12} sx={{p: 0, pb: 2, overflowY: 'scroll', flex: '1 1 auto !important', height: '0px'}}>
            <Grid container sx={{p: 1}}>
                <Grid item xs={12}>
                    <Typography variant="h6">Create your content section</Typography>
                    <Typography variant="body1">
                        Fill out the following fields to create a section for your writing template. The user input for this section will always be a text or a URL
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{p: 1}} gap={2}>
                <Grid
                    item
                    sx={{
                        flex: '1 1 0',
                    }}>
                    <TextField
                        required
                        label="Section Name"
                        placeholder='Provide a name for the section. Eg: "Summary"'
                        value={selectedSection.name || ''}
                        variant="filled"
                        fullWidth
                        onChange={e => handleContentChange('name', e.target.value)}
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        flex: '1 1 0',
                        p: 0.9,
                        border: '1px solid #c2c2c2',
                        borderRadius: '4px',
                    }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{
                                    pl: 2,
                                }}
                                checked={selectedSection.isPlaceholder}
                                onChange={e => handleContentChange('isPlaceholder', e.target.checked)}
                                name="isPlaceholder"
                                color="primary"
                            />
                        }
                        label="Is this a placeholder section?"
                    />
                </Grid>
            </Grid>

            {/* Checkbox to ask if the section is a placeholder */}
            {/* <Grid container sx={{p: 1}}></Grid> */}

            {!selectedSection.isPlaceholder && (
                <Grid container sx={{p: 1}}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Directions for this section"
                            placeholder="Write an email newsletter-style headline for the user input. The headline should always be under 12 words."
                            value={selectedSection.prompt || ''}
                            variant="filled"
                            fullWidth
                            onChange={e => handleContentChange('prompt', e.target.value)}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid container sx={{p: 1}}>
                <Grid item xs={12}>
                    <Typography variant="body1">Include a writing example below that tells inkform how to write this section.</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        backgroundColor: 'rgba(0, 0, 0, 0.06)',
                        padding: 2,
                        '.contentEditorWrapper': {
                            width: '100%',
                            // temporary fix for the editor height
                            height: '200px',
                        },
                        '.contentEditor': {
                            width: '100%',
                            height: '160px',
                            overflowY: 'auto',
                            backgroundColor: 'transparent',
                            paddingY: '0px',
                            marginTop: '-10px',
                        },
                        '.contentEditorToolbar': {
                            padding: '0px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            marginTop: '10px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            marginLeft: '-10px',
                        },
                        '.rdw-option-wrapper': {
                            border: 'none',
                            backgroundColor: 'transparent',
                        },
                        '.rdw-dropdown-wrapper': {
                            border: 'none',
                            backgroundColor: 'transparent',
                        },
                    }}>
                    <Typography variant={'body1'} sx={{color: grey[800]}}>
                        {selectedSection.isPlaceholder ? 'Placeholder Text' : 'Desired output (writing example)'}
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            color: grey[800],
                        }}>
                        E.g. Headline: Google Admits Al Content Here to Stay in Latest Update.
                    </Typography>
                    <Editor
                        editorState={exampleEditorState}
                        toolbarClassName="contentEditorToolbar"
                        wrapperClassName="contentEditorWrapper"
                        editorClassName="contentEditor"
                        onEditorStateChange={newEditorState => handleExampleEditorStateChange(newEditorState)}
                        handlePastedText={() => false}
                        onFocus={() => setExampleEditorFocused(true)}
                        onBlur={() => setExampleEditorFocused(false)}
                    />
                </Grid>
            </Grid>
            {!selectedSection.isPlaceholder && (
                <Grid container sx={{p: 1}}>
                    <Grid item xs={10}>
                        <Typography variant="h6">Test your content section</Typography>
                        <Typography variant="body1">
                            See if your section works the way you want by adding a test user input, then click <b>"Test"</b> to see the sections output in the "Preview"
                            window.
                        </Typography>
                    </Grid>
                    <Grid item xs={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Button variant="contained" startIcon={<Notes />} onClick={testSection} size="large">
                            Test
                        </Button>
                    </Grid>
                </Grid>
            )}
            {!selectedSection.isPlaceholder && (
                <Grid container gap={2} sx={{p: 1}}>
                    <Grid item sx={{flex: '1 1 0'}}>
                        {/* Replace this with editor */}
                        <TextField
                            label="Test user input"
                            multiline
                            rows={8}
                            value={selectedSection.sampleInput || ''}
                            placeholder="Enter an appropriate user input here to test how the section executes"
                            variant="filled"
                            onChange={e => handleContentChange('sampleInput', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sx={{flex: '1 1 0'}}>
                        <div
                            style={{
                                maxWidth: '100%',
                                height: '100%',
                                padding: '10px',
                                overflowX: 'scroll',

                                borderRadius: '4px',
                                backgroundColor: 'rgba(0, 0, 0, 0.06)',
                            }}
                            dangerouslySetInnerHTML={{__html: handleNewLines(selectedSection.preview) || ''}}></div>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default SectionForm;
