import Layout from 'container/Layout';
import {createBrowserRouter, redirect} from 'react-router-dom';
import {AuthProvider, loginLoader, protectedLoader} from 'utils/auth';

import { publicRoutes, protectedRoutes } from 'router/routes';

const router = createBrowserRouter([
    // Public routes such as the login, signup and password reset routes
    ...publicRoutes,

    // Protected routes such as the dashboard, profile and settings routes
    {
        id: 'root',
        path: '/',
        loader: protectedLoader,
        Component: Layout,

        // All the protected routes are nested inside this route
        children: protectedRoutes
    },

    // Logout route
    {
        path: '/logout',
        async loader() {
            try {
                await AuthProvider.signout();
            } catch (error) {
                console.log(error);
            }
            return redirect('/login');
        },
    },
]);

export default router;
