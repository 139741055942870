import Page from './Page';
import Widgets from '@mui/icons-material/Widgets';

const routes = [
    {
        id: 'history',
        name: 'History',
        path: '/history',
        showInSidebar: true,
        sidebarPosition: 4,
        icon: Widgets,
        Component: Page,
    },
    {
        id: 'history-selected',
        name: 'History',
        path: '/history/:id',
        parentId: 'history',
        Component: Page,
    },
];

export default routes;
