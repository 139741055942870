import Page from './Page';

const routes = [
    {
        id: 'verify-email',
        name: 'Verify Email',
        path: '/verify-email',
        Component: Page,
    },
];

export default routes;
