import React, {useEffect, useState, useRef, useImperativeHandle, forwardRef} from 'react';
import {Avatar, Grid, List, ListItemAvatar, ListItemText, CircularProgress, Typography} from '@mui/material';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {grey} from '@mui/material/colors';
import ViewDay from '@mui/icons-material/ViewDay';
import {Template} from 'models/template';
import {Section} from 'models/section';
import {requests} from 'requests';
import {reorder} from 'utils/helpers';
import {ListItemButton} from '@mui/material';
import {IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyAll from '@mui/icons-material/CopyAll';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import {History} from 'models/history';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

interface HistoryListProps {
    template: Template;
    onVersionSelect: (history: History) => void;
    onError?: (error: Error) => void;
}

const HistoryList = forwardRef((props: HistoryListProps, ref) => {
    const {template, onVersionSelect, onError} = props;

    const [history, setHistory] = useState<History[]>([]);
    const [selectedVersion, setSelectedVersion] = useState(new History({}));
    const [loading, setLoading] = useState<boolean>(false);
    const gridRef = useRef<HTMLDivElement>(null);

    const fetchHistory = async () => {
        try {
            setLoading(true); // Start loading when fetching begins
            let res = await requests.templates.history(template._id);

            // sort the history by date created
            res.sort((a: any, b: any) => {
                return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
            });

            setHistory(res.map((item: any) => new History(item)));

            setSelectedVersion(new History(res[0]));
            onVersionSelect(new History(res[0]));
        } catch (error) {
            onVersionSelect(new History({}));
            setHistory([]);
        } finally {
            setLoading(false); // Stop loading after fetching is complete (whether success or error)
        }
    };

    useEffect(() => {
        setLoading(true); // Start loading when fetching begins
        fetchHistory();
    }, [template, onError]);

    // check if template is empty
    if (template.operations.length === 0) {
        return (
            // Center the text
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Typography variant="body1">Please select a template</Typography>
            </Grid>
        );
    }

    // Check if history is empty
    if (history.length === 0) {
        return (
            // Center the text
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Typography variant="body1">No history found</Typography>
            </Grid>
        );
    }

    return (
        <>
            {loading ? (
                // Center the spinner
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid item xs={12} sx={{p: 0, overflowY: 'scroll', flex: '1 1 auto !important', height: '0px'}} ref={gridRef}>
                    <List>
                        {history.map((item: History, index: number) => (
                            <ListItemButton
                                key={item.getID()}
                                onClick={() => {
                                    setSelectedVersion(new History(item));
                                    onVersionSelect(new History(item));
                                }}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            background: selectedVersion.getID() === item.getID() ? grey[900] : grey[500],
                                        }}>
                                        <HistoryToggleOffIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    // Show the date in a readable format (e.g. 2021-10-01T00:00:00.000Z -> 30/1/2021, 8:00:00 AM)
                                    primary={new Date(item.dateCreated).toLocaleString()}
                                    sx={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        color: selectedVersion.getID() === item.getID() ? grey[900] : grey[500],
                                    }}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
            )}
        </>
    );
});

export default HistoryList;
