import AddIcon from '@mui/icons-material/Add';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import {Typography} from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import CustomDialog from 'components/Dialog';
import WriterSections from 'components/AiWriter/WriterSections';
import SectionSources from 'components/AiWriter/SectionSources';
import SelectTemplate from 'components/AiWriter/SelectTemplate';
import CustomSnackbar from 'components/Snackbar';
import {Section} from 'models/section';
import {Template} from 'models/template';
import {useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, ContentState, Modifier} from 'draft-js';
import Fab from '@mui/material/Fab';
import {requests} from 'requests';
import {Operation} from 'models/operation';
import type {CustomDialogProps} from 'components/Dialog';
import type {CustomSnackbarProps} from 'components/Snackbar';
import ApplyWritingRule from 'components/AiWriter/ApplyWritingRule';
import {writingRules} from 'requests/writingRules';
import {WritingRule} from 'models/writingRule';
import HistoryList from 'components/History/HistoryList';
import {History} from 'models/history';
import {stateFromHTML} from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface WriterSectionsRef {
    getAllSections: () => Section[];
}

const Page = () => {
    const navigate = useNavigate();
    const params = useParams();

    // identify the window height
    const windowHeight = window.innerHeight;
    // identify the window width
    const windowWidth = window.innerWidth;

    // Get the template id from the params if available
    const templateId = params['id'] || '';

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    // State to store the currently selected section
    const [selectedSection, setSelectedSection] = useState(new Section({}));

    // Template
    const [selectedTemplate, setSelectedTemplate] = useState(
        new Template({
            name: '',
            operations: [],
        }),
    );

    // Editor state
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const writerSectionsRef = useRef<WriterSectionsRef | null>(null);

    // Handle selected template
    const handleSelectedTemplate = (template: Template) => {
        setSelectedTemplate(template);
    };

    const htmlToDraftWrapper = (html: string) => {
        const blocksFromHtml = htmlToDraft(html);
        const {contentBlocks, entityMap} = blocksFromHtml;
        const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const newEditorState = EditorState.createWithContent(newContentState);
        return newEditorState;
    };

    // Handle selected version
    const handleSelectedVersion = (version: History) => {
        // check if version is empty
        if (!version?.content) {
            // set the editor state to empty
            setEditorState(EditorState.createEmpty());
            return;
        }

        type ResultItem = {result: string[] | string; cost: number};

        const extractStrings = (item: ResultItem): string => {
            if (Array.isArray(item.result)) {
                return item.result.join('{newline}');
            }
            return item.result;
        };

        // Extract the strings from the nested arrays
        let extractedStrings = version.content.map(extractStrings).join('{newline}');

        // Replace one or more than one \n with a single <br />
        // extractedStrings = extractedStrings.replace(/\n+/g, '<br />');

        // Replace {newline} with \n\n
        extractedStrings = extractedStrings.replace(/{newline}/g, '<br/>');

        const newEditorState = htmlToDraftWrapper(extractedStrings);

        // Set this new editor state
        setEditorState(newEditorState);
    };

    // Handle editor state change
    const handleEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };

    const handleError = (error: Error) => {
        setSnackBarState({
            open: true,
            message: error.message,
            severity: 'error',
            autoHideDuration: 5000,
            onClose: () => {
                setSnackBarState({...snackBarState, open: false});
            },
        });
    };

    return (
        // Main grid container
        <Grid container spacing={0} sx={{flexDirection: 'column'}}>
            <Grid item container flexGrow={1} flex={1}>
                {/* Sidebar grid item */}
                <Grid
                    item
                    md={3}
                    sx={{
                        // Add a slight border to the right of the sidebar
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <SelectTemplate selectedTemplateId={templateId} onTemplateSelect={handleSelectedTemplate} onError={() => {}} />

                    <HistoryList
                        template={selectedTemplate}
                        onVersionSelect={history => {
                            handleSelectedVersion(history);
                        }}
                        ref={writerSectionsRef}
                    />
                </Grid>
                <Grid
                    item
                    md={9}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '.contentEditorWrapper': {
                            width: '100%',
                            // temporary fix for the editor height
                            height: windowWidth < 1380 ? `${windowHeight - 265}px` : `${windowHeight - 230}px`,
                        },
                        '.contentEditor': {
                            paddingX: '10px',
                            width: '100%',
                            height: '100%',
                            overflowY: 'auto',
                            backgroundColor: 'white',
                        },
                        '.contentEditorToolbar': {
                            padding: '10px',
                            paddingTop: '15px',
                        },
                    }}>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="contentEditorToolbar"
                        wrapperClassName="contentEditorWrapper"
                        editorClassName="contentEditor"
                        onEditorStateChange={newEditorState => handleEditorStateChange(newEditorState)}
                        handlePastedText={() => false}
                    />
                </Grid>
            </Grid>

            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Grid>
    );
};

export default Page;
