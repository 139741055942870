import React, {useEffect, useState, useRef, useImperativeHandle, forwardRef} from 'react';
import {Avatar, Grid, List, ListItemAvatar, ListItemText, CircularProgress, Typography, ListItem, Fab} from '@mui/material';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {grey} from '@mui/material/colors';
import ViewDay from '@mui/icons-material/ViewDay';
import {Template} from 'models/template';
import {Section} from 'models/section';
import {requests} from 'requests';
import {reorder} from 'utils/helpers';
import {ListItemButton} from '@mui/material';
import {IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyAll from '@mui/icons-material/CopyAll';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface SelectSectionProps {
    template: Template;
    onSectionSelect: (section: Section) => void;
    onError?: (error: Error) => void;
}

const WriterSections = forwardRef((props: SelectSectionProps, ref) => {
    const {template, onSectionSelect, onError} = props;

    // State to store the sections. These can be added, deleted, reordered, etc.
    const [sections, setSections] = useState<Section[]>([]);

    // State to store the original sections. This is used to reset the sections when needed.
    const [originalSections, setOriginalSections] = useState<Section[]>([]);

    const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const sectionMenuOpen = Boolean(sectionMenuAnchorEl);

    const [selectedSection, setSelectedSection] = useState<Section>(
        new Section({
            _id: '',
        }),
    );
    const [loading, setLoading] = useState<boolean>(false);
    const gridRef = useRef<HTMLDivElement>(null);

    // Expose a function to get the sections
    useImperativeHandle(ref, () => ({
        getAllSections: () => {
            return sections;
        },
    }));

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const reorderedSections = reorder(sections, result.source.index, result.destination.index);

        setSections(reorderedSections);
    };

    const handleCopy = (section: Section) => {
        // 1. Create a copy of the current section using the copyWithID method
        const copiedSection = section.copyWithID();
        copiedSection.name = `${copiedSection.name}`;

        // 2. Clone the current state of the sections array
        const newSections = [...sections];

        // 3. Find the index of the current section in the sections array
        const index = newSections.findIndex(item => item.getKey() === section.getKey());

        // 4. If the current section is found, insert the copied section right after it
        if (index !== -1) {
            newSections.splice(index + 1, 0, copiedSection);

            // 5. Update the state with the new sections array
            setSections(newSections);

            // 6. Set the copied section as the selected section
            setSelectedSection(copiedSection);
        } else {
            // 7. If the current section is not found, add the copied section to the end of the sections array
            newSections.push(copiedSection);

            // 8. Update the state with the new sections array
            setSections(newSections);

            // 9. Set the copied section as the selected section
            setSelectedSection(copiedSection);
        }
    };

    const handleDelete = (section: Section) => {
        // 1. Filter out the section to be deleted from the sections array
        const newSections = sections.filter(item => item.getKey() !== section.getKey());

        // 2. Update the state with the new sections array
        setSections(newSections);

        // 3. If the deleted section is the currently selected section...
        if (selectedSection.getKey() === section.getKey() && newSections.length > 0) {
            // 4. Set the next available section (if any) as the selected section
            setSelectedSection(newSections[0]);
            onSectionSelect(newSections[0]);
        }
    };

    const fetchSection = async (sectionId: string) => {
        try {
            let res = await requests.sections.get(sectionId);
            return res;
        } catch (error) {
            return null;
        }
    };

    const fetchSections = async () => {
        try {
            // Fetch all sections in parallel
            const fetchPromises = template.operations.map(op => fetchSection(op.op));
            const sectionsData = await Promise.all(fetchPromises);

            const fetchedSections = sectionsData
                .filter(section => section) // Filter out any null or undefined sections
                .map(section => new Section(section)); // Convert to Section objects

            setSections(fetchedSections);
            setOriginalSections(fetchedSections);
            setSelectedSection(fetchedSections[0]);
            onSectionSelect(fetchedSections[0]);
        } catch (error) {
            // Handle error if needed (e.g., set some error state or show a message)
        } finally {
            setLoading(false); // Stop loading after fetching is complete (whether success or error)
        }
    };

    useEffect(() => {
        setLoading(true); // Start loading when fetching begins
        fetchSections();
    }, [template, onError]);

    // check if template is empty
    if (template.operations.length === 0) {
        return (
            // Center the text
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Typography variant="body1">Please select a template</Typography>
            </Grid>
        );
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {loading ? (
                // Center the spinner
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Droppable droppableId="writerSectionsList">
                    {provided => (
                        <Grid item xs={12} sx={{p: 0, overflowY: 'scroll', flex: '1 1 auto !important', height: '0px'}} ref={gridRef} {...provided.droppableProps}>
                            <List ref={provided.innerRef} {...provided.droppableProps}>
                                {sections.map((item: Section, index: number) => (
                                    <Draggable key={item.getKey()} draggableId={item.getKey()} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItemButton
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => {
                                                    setSelectedSection(item);
                                                    onSectionSelect(item);
                                                }}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    background: snapshot.isDragging ? grey[300] : 'transparent',
                                                }}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            background: selectedSection.getKey() === item.getKey() ? grey[900] : grey[500],
                                                        }}>
                                                        <ViewDay />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={item.name}
                                                    sx={{
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        color: selectedSection.getKey() === item.getKey() ? 'black' : 'grey.800',
                                                    }}
                                                />
                                                <Tooltip title="Copy Section">
                                                    <IconButton edge="end" onClick={() => handleCopy(item)}>
                                                        <Add />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete Section">
                                                    <IconButton edge="end" onClick={() => handleDelete(item)}>
                                                        <Remove />
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemButton>
                                        )}
                                    </Draggable>
                                ))}
                                {/* Add a plus button to add a new section */}
                                <ListItem sx={{justifyContent: 'end'}}>
                                    <Tooltip title="Add Section">
                                        {/* Round button */}
                                        <Fab
                                            sx={{boxShadow: 0}}
                                            size="small"
                                            aria-label="add"
                                            id="add-section"
                                            onClick={e => {
                                                setSectionMenuAnchorEl(e.currentTarget);
                                            }}>
                                            <Add />
                                        </Fab>
                                    </Tooltip>
                                </ListItem>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={sectionMenuAnchorEl}
                                    open={sectionMenuOpen}
                                    onClose={() => {
                                        setSectionMenuAnchorEl(null);
                                    }}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}>
                                    {originalSections.map((item: Section, index: number) => (
                                        <MenuItem
                                            key={item.getKey()}
                                            onClick={() => {
                                                handleCopy(item);
                                                setSectionMenuAnchorEl(null);
                                            }}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Menu>

                                {provided.placeholder}
                            </List>
                        </Grid>
                    )}
                </Droppable>
            )}
        </DragDropContext>
    );
});

export default WriterSections;
