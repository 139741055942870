import AddIcon from '@mui/icons-material/Add';
import {Alert, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import WritingRulesCard from 'components/WritingRules/WritingRulesCard';
import EditableWritingRulesForm from 'components/WritingRules/EditableWritingRulesForm';
import {WritingRule} from 'models/writingRule';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {requests} from 'requests';
import {User} from 'models/user';
import { pluralize } from 'utils/helpers';

const Page = () => {
    const navigate = useNavigate();
    const user = User.getInstance();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null as string | null);

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    const [writingRules, setWritingRules] = useState([] as WritingRule[]);

    // Editable Writing Rule
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRule, setSelectedRule] = useState(new WritingRule({}) as WritingRule);

    // Handle create new writing rule
    const handleCreateNew = () => {
        setSelectedRule(new WritingRule({}));
        setIsFormOpen(true);
    };

    // Handle view click
    const handleViewClick = (rule: WritingRule) => {
        setSelectedRule(rule);
        setIsFormOpen(true);
    };

    // Handle edit click
    const handleEditClick = (rule: WritingRule) => {
        setSelectedRule(rule);
        setIsFormOpen(true);
    };

    // Confirm delete
    const confirmDelete = (id: string) => {
        setDialogState({
            open: true,
            title: 'Delete Writing Rule',
            message: 'Are you sure you want to delete this writing rule?',
            onClose: () => setDialogState({...dialogState, open: false}),
            button1Text: 'Cancel',
            button1Action: () => {
                setDialogState({...dialogState, open: false});
            },
            button2Text: 'Delete',
            button2Variant: 'contained',
            button2Color: 'error',
            button2Action: () => {
                deleteWritingRule(id);
                setDialogState({...dialogState, open: false});
            },
        });
    };

    // delete a writing rule
    const deleteWritingRule = async (id: string) => {
        try {
            await requests.writingRules.delete(id);
            setSnackBarState({
                open: true,
                message: 'Writing rule deleted successfully.',
                severity: 'success',
                onClose: () => setSnackBarState({...snackBarState, open: false}),
            });

            fetchWritingRules();
        } catch (error) {
            setSnackBarState({
                open: true,
                message: 'Error deleting the writing rule. Please try again.',
                severity: 'error',
                onClose: () => setSnackBarState({...snackBarState, open: false}),
            });
        }
    };

    async function fetchWritingRules() {
        try {
            let res = await requests.writingRules.list();
            setWritingRules(res.map((rule: any) => new WritingRule(rule)));
            setIsLoading(false);
        } catch (error) {
            setError('Error fetching templates. Please try again later.');
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchWritingRules();
    }, []);

    return (
        <>
            <Grid container spacing={2} sx={{p: 3, overflowY: 'scroll'}} alignContent={'flex-start'}>
                {user?.getSelectedOrganization()?.getUsage()?.templateLimitReached && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Alert
                            severity="warning"
                            sx={{
                                border: '1px solid rgba(0, 0, 0, 0.10)',
                            }}>
                            You have reached your limit of {user?.getSelectedOrganization()?.getUsage()?.maxRules} writing {pluralize('rule', user?.getSelectedOrganization()?.getUsage()?.maxRules)}. Please upgrade your plan to add more.. Please upgrade your plan to add more.
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" gutterBottom>
                        Click{' '}
                        <Typography style={{display: 'inline', fontSize: '20px'}}>
                            <b>+</b>
                        </Typography>{' '}
                        to create a new writing rule, or check out an example writing rule for inspiration.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <ButtonBase
                        sx={{width: '100%'}}
                        onClick={() => {
                            handleCreateNew();
                        }}>
                        <Card sx={{width: '100%'}}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight={230}
                                width="100%"
                                borderRadius="borderRadius"
                                style={{cursor: 'pointer'}}>
                                <AddIcon style={{fontSize: 80}} />
                            </Box>
                        </Card>
                    </ButtonBase>
                </Grid>
                {isLoading && (
                    <Grid item xs={12} sm={6} md={3} lg={3} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress />
                    </Grid>
                )}

                {error && (
                    <Grid item xs={12} sm={6} md={3} lg={3} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p>{error}</p>
                    </Grid>
                )}

                {writingRules?.map((rule: WritingRule) => (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={rule._id}>
                        <WritingRulesCard
                            name={rule.name}
                            prompt={rule.prompt}
                            onEditClick={() => {
                                handleEditClick(rule);
                            }}
                            onDeleteClick={() => {
                                // Check if isExample is true
                                if (rule.isExample) {
                                    setSnackBarState({
                                        open: true,
                                        message: 'You cannot delete an example writing rule.',
                                        severity: 'error',
                                        onClose: () => setSnackBarState({...snackBarState, open: false}),
                                    });
                                    return;
                                }

                                confirmDelete(rule._id);
                            }}
                        />
                    </Grid>
                ))}

                {snackBarState.open && <CustomSnackbar {...snackBarState} />}

                {dialogState.open && <CustomDialog {...dialogState} />}

                {isFormOpen && (
                    <EditableWritingRulesForm
                        open={isFormOpen}
                        onClose={() => {
                            setIsFormOpen(false);
                            fetchWritingRules();
                        }}
                        writingRule={selectedRule}
                    />
                )}
            </Grid>
        </>
    );
};

export default Page;
