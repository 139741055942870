import Page from './Page';

const routes = [
    {
        id: 'reset-password',
        name: 'Reset Password',
        path: '/reset-password',
        Component: Page,
    },
];

export default routes;
