import {apiRequest, authenticatedApiRequest, APIRequestType, APIResponse} from 'utils/api';
import {Section} from 'models/section';

export const pseudo = {
    // Paraphrase a text
    async paraphrase(text: string): Promise<[]> {
        const response = await authenticatedApiRequest(APIRequestType.POST, 'tools/pseudo/paraphrase', {data: {text}});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Shorten a text
    async shorten(text: string): Promise<[]> { 
        const response = await authenticatedApiRequest(APIRequestType.POST, 'tools/pseudo/shorten', {data: {text}});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Lengthen a text
    async lengthen(text: string): Promise<[]> {
        const response = await authenticatedApiRequest(APIRequestType.POST, 'tools/pseudo/lengthen', {data: {text}});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },
};
