export class Plan {
    _id: any;
    name: string;
    seatLimit: number;
    tokenLimit: number;
    templateLimit: number;
    ruleLimit: number;
    dateCreated: Date;

    constructor(data: any) {
        this._id = data._id;
        this.name = data.name;
        this.seatLimit = data.seatLimit;
        this.tokenLimit = data.tokenLimit;
        this.templateLimit = data.templateLimit;
        this.ruleLimit = data.ruleLimit;
        this.dateCreated = data.dateCreated;
    }

    getID() {
        return this._id;
    }

    setID(id: any) {
        this._id = id;
    }
}
