import Page from "./Page";
import Widgets from '@mui/icons-material/Widgets';
import AccountCircle from '@mui/icons-material/AccountCircle';

const routes = [
    {
        id: 'force-change-password',
        name: 'Force change password',
        path: '/force-change-password',
        Component: Page,
    },
]

export default routes;