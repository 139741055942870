import {apiRequest, authenticatedApiRequest, APIRequestType, APIResponse} from 'utils/api';
import {Template} from 'models/template';

export const templates = {
    // List all templates
    async list(): Promise<Array<Template>> {
        const response = await authenticatedApiRequest(APIRequestType.GET, 'tools/template');

        if (response.ok()) {
            let res = response.body();
            return res.map((template: any) => new Template(template));
        }
        throw new Error(response.getError());
    },

    // Get a template by id
    async get(id: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.GET, `tools/template/${id}`);

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Create a template
    async create(template: Template): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, 'tools/template', {data: template.createBody()});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Update a template
    async update(template: Template): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.PATCH, `tools/template/${template._id}`, {data: template.updateBody()});

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    // Delete a template
    async delete(id: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.DELETE, `tools/template/${id}`);

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },

    // Run a template
    async run(template: Template): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.POST, `tools/template/run/${template._id}`, {data: template.runBody()});

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },

    // History
    async history(templateId: string): Promise<any> {
        const response = await authenticatedApiRequest(APIRequestType.GET, `tools/template/${templateId}/history`);

        if (response.ok()) {
            return response.body();
        }

        throw new Error(response.getError());
    },
};
