import {ButtonBase, Divider, Grid, ListItemIcon} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {alpha, styled} from '@mui/material/styles';
import {User} from 'models/user';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {getRouteById} from 'router/routes';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpOutline from '@mui/icons-material/HelpOutline';
import {formatNumber} from 'utils/helpers';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import useDisplayProductTourButton from 'hooks/productTour';
import CreditCardIcon from '@mui/icons-material/CreditCard';
interface HeaderProps {
    toggleDrawer: () => void;
}

const Header: React.FC<HeaderProps> = ({toggleDrawer}) => {
    const navigate = useNavigate();
    const [rerender, setRerender] = React.useState(0);

    const user = User.getInstance();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElOrg, setAnchorElOrg] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        user.fetchSelf().then(() => {
            setRerender(prev => prev + 1); // force a re-render after fetching
        });
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenOrgMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElOrg(event.currentTarget);
    };

    const handleCloseOrgMenu = () => {
        setAnchorElOrg(null);
    };

    const handleUsageClick = () => {
        navigate(getRouteById('usage')?.path || '');
        handleCloseUserMenu();
    };

    useDisplayProductTourButton();

    React.useEffect(() => {
        user.fetchSelf().then(() => {
            setRerender(prev => prev + 1); // force a re-render after fetching
        });
    }, []);

    return (
        <AppBar position="fixed" sx={{zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: 'transparent', color: 'inherit'}} elevation={0}>
            <Toolbar>
                {/* <Box sx={{flexGrow: 0}}>
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{mr: 2}} onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Box> */}
                <Box sx={{flexGrow: 0, marginLeft: 0}}>
                    <img src={require('assets/images/logo.svg').default} alt="logo" style={{width: '100px', filter: 'invert(0)'}} />
                </Box>
                <Box sx={{flexGrow: 1}} />

                {/* Product Tour Placeholder */}
                <Box mr={2} sx={{flexGrow: 0}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} p={0} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title="Start Product Tour" className="product-tour">
                            <ButtonBase sx={{p: 1, px: 2, borderRadius: 50, border: '1px solid rgba(0, 0, 0, 0.2)'}}>
                                <SlideshowIcon />

                                <Typography variant="body1" ml={1} sx={{display: {xs: 'none', md: 'block'}}}>
                                    Start Product Tour
                                </Typography>
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                </Box>

                <Box mr={2} sx={{flexGrow: 0}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} p={0} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title="Help" className="header-help">
                            <ButtonBase
                                sx={{p: 1, px: 2, borderRadius: 50, border: '1px solid rgba(0, 0, 0, 0.2)'}}
                                onClick={
                                    // Open a new tab with the help page
                                    () => window.open(`${process.env.REACT_APP_WEBSITE_BASE_URL}/resources`, '_blank')
                                }>
                                <HelpOutline />

                                <Typography variant="body1" ml={1} sx={{display: {xs: 'none', md: 'block'}}}>
                                    Help
                                </Typography>
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                </Box>

                {/* Dropdown to switch org */}
                <Box mr={2} sx={{flexGrow: 0}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} p={0} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title="Switch Organization">
                            <ButtonBase sx={{p: 1, px: 2, borderRadius: 50, border: '1px solid rgba(0, 0, 0, 0.2)'}} onClick={handleOpenOrgMenu}>
                                <ApartmentIcon />
                                <Typography variant="body1" ml={1} sx={{display: {xs: 'none', md: 'block'}}}>
                                    {user?.getSelectedOrganization()?.name}
                                </Typography>
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    <Menu
                        sx={{
                            mt: '45px',
                            width: '300px',
                            '& .MuiMenu-paper': {
                                minWidth: '250px',
                            },
                        }}
                        id="menu-appbar"
                        anchorEl={anchorElOrg}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElOrg)}
                        onClose={handleCloseOrgMenu}>
                        {user?.organization?.map(org => (
                            <MenuItem
                                key={org.getID()}
                                onClick={() => {
                                    user.setSelectedOrganization(org.getID());
                                    handleCloseOrgMenu();
                                    // refresh the page
                                    window.location.reload();
                                }}>
                                <ListItemIcon>
                                    <ApartmentIcon />
                                </ListItemIcon>
                                <Typography textAlign="center">{org.name}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{flexGrow: 0}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} p={0} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title="Settings">
                            <ButtonBase sx={{p: 1, px: 2, borderRadius: 50, border: '1px solid rgba(0, 0, 0, 0.2)'}} onClick={handleOpenUserMenu}>
                                <AccountCircle />
                                <Typography variant="body1" ml={1} sx={{display: {xs: 'none', md: 'block'}}}>
                                    {user?.name}
                                </Typography>
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    <Menu
                        sx={{
                            mt: '45px',
                            width: '300px',
                            '& .MuiMenu-paper': {
                                minWidth: '250px',
                            },
                        }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}>
                        <MenuItem
                            key={'profile'}
                            onClick={() => {
                                handleCloseUserMenu();
                                navigate(getRouteById('profile')?.path || '');
                            }}>
                            <ListItemIcon>
                                <AccountCircle />
                            </ListItemIcon>
                            <Typography textAlign="center">Profile</Typography>
                        </MenuItem>
                        <MenuItem
                            key={'logout'}
                            onClick={() => {
                                handleCloseUserMenu();
                                navigate('/logout');
                            }}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">Logout</Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem key={'token-usage'} onClick={handleUsageClick}>
                            <ListItemIcon>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">
                                <b>{user?.getSelectedOrganization()?.isOnTrialPlan() ? 'Trial' : user?.getSelectedOrganization()?.getPlanName()} Plan</b>
                            </Typography>
                        </MenuItem>
                        <MenuItem key={'token-usage'} onClick={handleUsageClick}>
                            <ListItemIcon>
                                <DataUsageIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">
                                {formatNumber(user?.getSelectedOrganization()?.getUsage().usedTokens, {}) || 0} /{' '}
                                {user?.getSelectedOrganization()?.isOnTrialPlan()
                                            ? formatNumber(user?.getSelectedOrganization()?.getTrialTokens(), {})
                                            : formatNumber(user?.getSelectedOrganization()?.getUsage().maxTokens, {}) || 0} tokens
                            </Typography>
                        </MenuItem>

                        <MenuItem key={'template-usage'} onClick={handleUsageClick}>
                            <ListItemIcon>
                                <ViewModuleIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">
                                {formatNumber(user?.getSelectedOrganization()?.getUsage().usedTemplates, {}) || 0} /{' '}
                                {formatNumber(user?.getSelectedOrganization()?.getUsage().maxTemplates, {}) || 0} templates
                            </Typography>
                        </MenuItem>
                        <MenuItem key={'writing-rules-usage'} onClick={handleUsageClick}>
                            <ListItemIcon>
                                <HistoryEdu />
                            </ListItemIcon>
                            <Typography textAlign="center">
                                {formatNumber(user?.getSelectedOrganization()?.getUsage().usedRules, {}) || 0} /{' '}
                                {formatNumber(user?.getSelectedOrganization()?.getUsage().maxRules, {}) || 0} writing rules
                            </Typography>
                        </MenuItem>
                        <MenuItem key={'seats-usage'} onClick={handleUsageClick}>
                            <ListItemIcon>
                                <PeopleAltIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">
                                {formatNumber(user?.getSelectedOrganization()?.getUsage().usedSeats, {}) || 0} /{' '}
                                {formatNumber(user?.getSelectedOrganization()?.getUsage().maxSeats, {}) || 0} seats
                            </Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
