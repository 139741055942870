import React, {ReactNode} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import type {ReactElement} from 'react';

// Define the interface for the props
export interface CustomDialogProps {
    open?: boolean;
    onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined;
    disableEscapeKeyDown?: boolean;
    title?: string | null;
    message?: string | null;
    button1Text?: string | null;
    button1Action?: any;
    button1Color?: 'primary' | 'secondary' | 'error';
    button1Variant?: 'text' | 'outlined' | 'contained';
    button2Text?: string | null;
    button2Action?: any;
    button2Color?: 'primary' | 'secondary' | 'error';
    button2Variant?: 'text' | 'outlined' | 'contained';
}

const CustomDialog: React.FC<CustomDialogProps> = ({
    open = false,
    onClose = () => {},
    disableEscapeKeyDown = false,
    title = '',
    message = '',
    button1Text = null,
    button1Action = () => {},
    button1Color = 'primary' as 'primary' | 'secondary' | 'error',
    button1Variant = 'text' as 'text' | 'outlined' | 'contained',
    button2Text = null,
    button2Action = () => {},
    button2Color = 'primary' as 'primary' | 'secondary' | 'error',
    button2Variant = 'text' as 'text' | 'outlined' | 'contained',
}) => {
    return (
        <Dialog open={open} onClose={onClose} disableEscapeKeyDown={disableEscapeKeyDown}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {message && <DialogContentText>{message}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                {button1Text && (
                    <Button onClick={button1Action} color={button1Color} variant={button1Variant}>
                        {button1Text}
                    </Button>
                )}
                {button2Text && (
                    <Button onClick={button2Action} color={button2Color} variant={button2Variant}>
                        {button2Text}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;
