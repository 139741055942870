import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {getRouteById, getRouteByPath, protectedRoutes} from 'router/routes';
import {useNavigate, useLocation} from 'react-router-dom';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';

const TopBar = () => {
    const navigate = useNavigate();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    const changeTab = (_: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTabIndex(newValue);
    };

    const location = useLocation();
    const currentRoute = getRouteByPath(location.pathname);

    const findSidebarPosition = (route): number => {
        if (route && route.sidebarPosition) {
            return route.sidebarPosition;
        } else if (route && route.parentId) {
            const parentRoute = getRouteById(route.parentId);
            return findSidebarPosition(parentRoute);
        } else {
            return 0;
        }
    };

    const handleRouteClick = route => {
        // Check if route has exitConfirmation and if so, show dialog
        if (currentRoute?.exitConfirmation) {
            setDialogState({
                open: true,
                message: currentRoute.exitConfirmation,
                button1Text: 'Cancel',
                button1Action: () => setDialogState({...dialogState, open: false}),
                button2Text: 'Yes',
                button2Action: () => {
                    setDialogState({...dialogState, open: false});
                    navigate(route.path);
                },
                button2Variant: 'contained',
                button2Color: 'error',
            });
        } else {
            navigate(route.path);
        }
    };

    // Redirect to my templates if the user is logged in and tries to access the root path
    if (location.pathname === '/') {
        navigate(getRouteById('my-templates')?.path || '/404');
    }

    useEffect(() => {
        const position = findSidebarPosition(currentRoute);
        setSelectedTabIndex(position - 1 || 0);
    }, [currentRoute]);

    return (
        <>
            <AppBar position="static" elevation={0} sx={{backgroundColor: 'transparent', color: 'inherit'}}>
                <Tabs
                    value={selectedTabIndex}
                    variant="scrollable"
                    textColor="inherit"
                    indicatorColor="secondary"
                    scrollButtons="auto"
                    aria-label="preview-window-tabs"
                    allowScrollButtonsMobile>
                    {protectedRoutes
                        .filter(route => route.showInSidebar)
                        .sort((a, b) => (a.sidebarPosition || 999) - (b.sidebarPosition || 999))
                        .map(route => (
                            <Tab key={route.id} label={route.name} sx={{color: 'black !important'}} onClick={() => handleRouteClick(route)} />
                        ))}
                </Tabs>
            </AppBar>
            {dialogState.open && <CustomDialog {...dialogState} />}
        </>
    );
};

export default TopBar;
