import Page from './Page';

const routes = [
    {
        id: 'accept-invite-existing-user',
        name: 'Accept Invite',
        path: '/accept-invite',
        Component: Page,
    },
];

export default routes;
