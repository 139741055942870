import {Alert, Button, Divider, Paper, Snackbar, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import PageSidebar from 'components/PageSidebar';
import {Link} from 'react-router-dom';
import {getRoutesByTags} from 'router/routes';
import {User} from 'models/user';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {toTitleCase} from 'utils/helpers';
import {requests} from 'requests';
import {validateEmail} from 'utils/validations';
import LoadingButton from '@mui/lab/LoadingButton';

const Page = () => {
    const user = User.getInstance();
    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    const [email, setEmail] = useState('');
    const [disableUserActions, setDisableUserActions] = useState(false);

    const handleInviteUser = async (email: string) => {
        // Validate email
        if (!validateEmail(email)) {
            setSnackBarState({
                open: true,
                message: `Invalid email address.`,
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
            return;
        }

        setDialogState({
            open: true,
            title: 'Inviting User',
            message: 'Please wait while we send an invitation to the user.',
        });
        
        try {
            setDisableUserActions(true);
            await requests.user.inviteUser(email);

            setSnackBarState({
                open: true,
                message: `Invitation sent to ${email}`,
                severity: 'success',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
        } catch (error) {
            setSnackBarState({
                open: true,
                message: `Error inviting user. Please try again later.`,
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
        } finally {
            setDisableUserActions(false);
            setDialogState({
                open: false,
            });
        }
    };

    return (
        <Grid container spacing={2} sx={{p: 2}}>
            <Grid item xs={2}>
                <PageSidebar routes={getRoutesByTags(['user-config'])} />
            </Grid>
            <Grid item xs={10} height={'100%'}>
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                    }}>
                    <Typography variant="h6">Users</Typography>

                    <TableContainer component={Paper} variant="outlined" sx={{mt: 2}}>
                        <Table sx={{minWidth: 650}} aria-label="Users">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>Email / Username</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>Role</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {user.getSelectedOrganization()?.users.map(u => (
                                    <TableRow key={u._id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell component="th" scope="row">
                                            {toTitleCase(u.name)}
                                        </TableCell>
                                        <TableCell>{u.username}</TableCell>
                                        <TableCell>{u._id === user.getSelectedOrganization()?.admin ? 'Admin' : 'User'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Paper
                    variant="outlined"
                    sx={{
                        mt: 2,
                        p: 2,
                    }}>
                    <Typography variant="h6">Invite User</Typography>

                    {/* Invite user by email */}
                    {!user?.isCurrentOrgAdmin() && (
                        <Alert severity="warning" sx={{mt: 2}}>
                            <Typography variant="body2">You are not an admin of this organization. Only admins can invite users.</Typography>
                        </Alert>
                    )}

                    {user?.isCurrentOrgAdmin() && (
                        <Grid container spacing={2} pt={2}>
                            <Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
                                <LoadingButton variant="contained" fullWidth onClick={() => handleInviteUser(email)} loading={disableUserActions}>
                                    Invite
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Grid>

            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Grid>
    );
};

export default Page;
