import Page from "./Page";
import ColorLensIcon from '@mui/icons-material/ColorLens';

const routes = [
    {
        id: 'theme',
        name: 'Theme',
        path: '/theme',
        showInSidebar: false,
        pageSidebarPosition: 0,
        icon: ColorLensIcon,
        Component: Page,
        tags: ['user-config'],
    },
]

export default routes;