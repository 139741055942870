import Page from './Page';
import DataUsageIcon from '@mui/icons-material/DataUsage';

const routes = [
    {
        id: 'usage',
        name: 'Usage',
        path: '/usage',
        showInSidebar: false,
        pageSidebarPosition: 3,
        icon: DataUsageIcon,
        Component: Page,
        tags: ['user-config'],
    },
];

export default routes;
