import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {truncateString} from 'utils/helpers';

interface WritingRulesCardProps {
    name: string;
    prompt: string;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
}

function WritingRulesCard({name, prompt, onEditClick = () => {}, onDeleteClick = () => {}}: WritingRulesCardProps) {
    const theme = useTheme();

    return (
        <Card sx={{display: 'flex', minHeight: 230}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto', pb: 0}}>
                    <Typography gutterBottom variant="h6" component="div">
                        {truncateString(name, 50)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {truncateString(prompt, 150)}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Tooltip title="Edit Template">
                        <IconButton size="large" color="primary" onClick={onEditClick}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy Template">
                        <IconButton size="large" color="primary" onClick={onDeleteClick}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Box>
        </Card>
    );
}

export default WritingRulesCard;
