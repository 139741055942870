import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MyTemplateCard from 'components/MyTemplateCard';
import {useNavigate} from 'react-router-dom';
import {getRouteById, replaceRouteParams} from 'router/routes';
import ButtonBase from '@mui/material/ButtonBase';
import {useEffect, useState} from 'react';
import {requests} from 'requests';
import {Template} from 'models/template';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import {ErrorResponse} from 'utils/api/error';
import {formatNumber, pluralize, showGreeting} from 'utils/helpers';
import {Alert, Typography} from '@mui/material';
import {functions} from 'utils/functions';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import {User} from 'models/user';

const Page = () => {
    const navigate = useNavigate();
    const user = User.getInstance();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null as string | null);
    const [myTemplates, setMyTemplates] = useState([] as Template[]);

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    async function fetchMyTemplates(): Promise<Template[]> {
        try {
            let res = await requests.templates.list();

            const templates = res.map((template: any) => new Template(template));
            setMyTemplates(templates);
            setIsLoading(false);

            return templates;
        } catch (error) {
            setError('Error fetching templates. Please try again later.');
            setIsLoading(false);
        }
        return [];
    }

    const handleCopyTemplate = async (templateId: string) => {
        setDialogState({
            open: true,
            title: 'Copying Template',
            message: 'Please wait while we make a copy of your template.',
        });

        try {
            await functions.template.duplicateTemplate(templateId);
            fetchMyTemplates();
            setDialogState({
                open: false,
            });
        } catch (error) {
            setSnackBarState({
                open: true,
                message: 'Error copying template. Please try again later.',
                severity: 'error',
                autoHideDuration: 5000,
                onClose: () => {
                    setSnackBarState({...snackBarState, open: false});
                },
            });
        }
    };

    useEffect(() => {
        // Fetch my templates
        fetchMyTemplates();
    }, []);
    return (
        <>
            <Grid container spacing={2} sx={{p: 3, overflowY: 'scroll'}} alignContent={'flex-start'}>
                {user?.getSelectedOrganization()?.isOnTrialPlan() && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Alert severity="warning" sx={{fontSize: '1rem', border: '1px solid rgba(0, 0, 0, 0.10)'}}>
                            You are currently on the <b>Trial Plan</b> with a limit of {formatNumber(user?.getSelectedOrganization()?.getTrialTokens(), {})} tokens. Your
                            upcoming plan is <b>{user?.getSelectedOrganization()?.getPlanName()}</b>. To manage your plan, please navigate to the Billing page.
                        </Alert>
                    </Grid>
                )}

                {user?.getSelectedOrganization()?.getUsage()?.templateLimitReached && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Alert
                            severity="warning"
                            sx={{
                                border: '1px solid rgba(0, 0, 0, 0.10)',
                            }}>
                            You have reached your limit of {user?.getSelectedOrganization()?.getUsage()?.maxTemplates} writing{' '}
                            {pluralize('template', user?.getSelectedOrganization()?.getUsage()?.maxTemplates)}. Please upgrade your plan to add more.
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" gutterBottom>
                        Click{' '}
                        <Typography style={{display: 'inline', fontSize: '20px'}}>
                            <b>+</b>
                        </Typography>{' '}
                        to create a new writing template, or check out an example template for inspiration. Make a copy of any example template to customize it. Click{' '}
                        <b>(?) Help</b> for a tutorial.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <ButtonBase
                        sx={{width: '100%'}}
                        onClick={() => {
                            const route = getRouteById('create-template');
                            if (route) {
                                navigate(route.path);
                            }
                        }}>
                        <Card sx={{width: '100%'}}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight={200}
                                width="100%"
                                borderRadius="borderRadius"
                                style={{cursor: 'pointer'}}>
                                <AddIcon style={{fontSize: 80}} />
                            </Box>
                        </Card>
                    </ButtonBase>
                </Grid>
                {isLoading && (
                    <Grid item xs={12} sm={6} md={3} lg={3} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress />
                    </Grid>
                )}

                {error && (
                    <Grid item xs={12} sm={6} md={3} lg={3} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p>{error}</p>
                    </Grid>
                )}

                {myTemplates?.map((template: any) => (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={template._id}>
                        <MyTemplateCard
                            title={template.name}
                            description={template.description}
                            onEditClick={() => {
                                const route = getRouteById('template-editor');
                                if (route) {
                                    navigate(replaceRouteParams(route.path, {id: template._id}));
                                }
                            }}
                            onWriteClick={() => {
                                const route = getRouteById('ai-writer-selected');
                                if (route) {
                                    navigate(replaceRouteParams(route.path, {id: template._id}));
                                }
                            }}
                            onCopyClick={() => {
                                handleCopyTemplate(template._id);
                            }}
                            onHistoryClick={() => {
                                const route = getRouteById('history-selected');
                                if (route) {
                                    navigate(replaceRouteParams(route.path, {id: template._id}));
                                }
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </>
    );
};

export default Page;
