import Page from './Page';
import HistoryEdu from '@mui/icons-material/HistoryEdu';

const routes = [
    {
        id: 'ai-writer',
        name: 'AI Writer',
        path: '/ai-writer',
        showInSidebar: true,
        sidebarPosition: 2,
        icon: HistoryEdu,
        exitConfirmation: "Are you sure you want to leave this page? Any unsaved changes will be lost.",
        Component: Page,
    },
    {
        id: 'ai-writer-selected',
        name: 'AI Writer',
        path: '/ai-writer/:id',
        parentId: 'ai-writer',
        exitConfirmation: "Are you sure you want to leave this page? Any unsaved changes will be lost.",
        Component: Page,
    },
];

export default routes;
