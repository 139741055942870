import React, {useEffect, useState} from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {requests} from 'requests';
import {Template} from 'models/template';

interface SelectTemplateProps {
    selectedTemplateId?: string;
    onTemplateSelect?: (template: Template) => void;
    onError?: (error: Error) => void;
}

const SelectTemplate: React.FC<SelectTemplateProps> = ({selectedTemplateId, onTemplateSelect, onError}) => {
    const [templates, setTemplates] = useState<Template[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

    async function fetchTemplates() {
        try {
            let res = await requests.templates.list();
            setTemplates(res);

            // If a template is selected, set the selected template
            if (selectedTemplateId) {
                const template = res.find(t => t._id === selectedTemplateId);
                if (template) {
                    setSelectedTemplate(template);
                    if (onTemplateSelect) {
                        onTemplateSelect(template);
                    }
                }
            }
        } catch (error) {
            console.error('Failed to fetch templates:', error);
            if (onError) {
                onError(error as Error);
            }
        }
    }

    const handleSelectedTemplate = (id: string) => {
        const template = templates.find(t => t._id === id);
        if (template) {
            setSelectedTemplate(template);
            if (onTemplateSelect) {
                onTemplateSelect(template);
            }
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    return (
        <Grid item xs={12} display={'flex'} flex={'0 !important'} gap={1} sx={{m: 2}}>
            <FormControl fullWidth>
                <InputLabel id="template-select-label">Select Template</InputLabel>
                <Select
                    labelId="template-select-label"
                    id="template-select"
                    value={selectedTemplate?._id || ''}
                    label="Select Template"
                    onChange={e => handleSelectedTemplate(e.target.value)}>
                    {templates.map(template => (
                        <MenuItem value={template._id} key={template._id}>
                            {template.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
};

export default SelectTemplate;
