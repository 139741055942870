import {requests} from 'requests';

export class History {
    _id: any;
    owner: string;
    templateId: string;
    content: { result: string[] | string, cost: number }[];
    dateCreated: Date;

    constructor(data: any) {
        this._id = data._id;
        this.owner = data.owner;
        this.templateId = data.templateId;
        this.content = data.content;
        this.dateCreated = data.dateCreated;
    }

    getID() {
        return this._id;
    }

    setID(id: any) {
        this._id = id;
    }
}
