import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {WritingRule} from 'models/writingRule';
import {useEffect, useState} from 'react';
import {requests} from 'requests';
import {Notes} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

const EditableWritingRulesForm = ({open, onClose, writingRule}: {open: boolean; onClose: () => void; writingRule: WritingRule}) => {
    const [rule, setRule] = useState(writingRule || (new WritingRule({}) as WritingRule));
    const [alert, setAlert] = useState({open: false, message: '', severity: 'error'} as any);
    const [sampleText, setSampleText] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setRule(new WritingRule({...rule, [name]: value}));
    };

    const [disableUserActions, setDisableUserActions] = useState(false);

    const applyWritingRule = async () => {
        // check if the rules are not empty
        if (!rule.prompt) {
            setAlert({open: true, message: 'Please enter your writing rules.', severity: 'error'});
            return;
        }

        // check if the sample text is not empty
        if (!sampleText) {
            setAlert({open: true, message: 'Please enter a sample text.', severity: 'error'});
            return;
        }

        try {
            setDisableUserActions(true);
            setAlert({open: true, message: 'Applying the writing rule...', severity: 'info'});
            const result = await requests.writingRules.runUnsaved(rule.prompt, sampleText);
            setSampleText(result.result);
            setAlert({open: true, message: 'Writing rule applied successfully.', severity: 'success'});
        } catch (error) {
            setAlert({open: true, message: 'Error applying the writing rule. Please try again.', severity: 'error'});
        } finally {
            setDisableUserActions(false);
        }
    };

    const handleSave = async () => {
        try {
            setDisableUserActions(true);
            if (writingRule._id) {
                await rule.updateInApi();
            } else {
                await rule.createInApi();
            }
            if (writingRule._id) {
                setAlert({open: true, message: 'Writing rule updated successfully.', severity: 'success'});
            } else {
                onClose();
            }
        } catch (error) {
            const message = writingRule._id ? 'Error updating the writing rule. Please try again.' : 'Error creating the writing rule. Please try again.';
            setAlert({open: true, message: message, severity: 'error'});
        } finally {
            setDisableUserActions(false);
        }
    };

    useEffect(() => {
        setRule(writingRule || new WritingRule({}));
    }, [writingRule]);

    useEffect(() => {
        setAlert({open: false, message: '', severity: 'error'});
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'lg'} fullScreen={false}>
            <DialogTitle>{writingRule._id ? 'Edit Writing Rule' : 'Create Writing Rule'}</DialogTitle>

            <DialogContent>
                {/* Alert */}
                {alert.open && (
                    <Box mb={2}>
                        <Alert severity={alert.severity}>{alert.message}</Alert>
                    </Box>
                )}

                <Grid container spacing={2} mt={0} alignContent={'flex-start'}>
                    <Grid item xs={12}>
                        <TextField id="name" name="name" label="Name" variant="outlined" fullWidth value={rule.name} onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="prompt"
                            name="prompt"
                            label="Writing Rules"
                            variant="outlined"
                            placeholder="Please enter your editorial rules here. Press enter after each rule. (e.g., 'Replace New York City with NYC')"
                            fullWidth
                            multiline
                            rows={8}
                            value={rule.prompt}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                {/* Test writing rule */}
                <Grid container spacing={2} mt={0} alignContent={'flex-start'}>
                    <Grid item xs={10}>
                        <Typography variant="h6">Test your writing rule</Typography>
                        <Typography variant="body2">
                            Input a sample text in the box below and click on the Test button to see apply the writing rule to the text.
                        </Typography>
                    </Grid>
                    <Grid item xs={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                        <LoadingButton variant="contained" startIcon={<Notes />} onClick={applyWritingRule} loading={disableUserActions}>
                            Test
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="text"
                            name="text"
                            label="Sample text"
                            variant="outlined"
                            placeholder="e.g. New York City comprises 5 boroughs sitting where the Hudson River meets the Atlantic Ocean. At its core is Manhattan, a densely populated borough that’s among the world’s major commercial, financial and cultural centers."
                            fullWidth
                            multiline
                            rows={8}
                            value={sampleText}
                            onChange={e => setSampleText(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}} px={2} pb={2}>
                        <Button onClick={onClose}>Cancel</Button>
                        <LoadingButton onClick={handleSave} variant="contained" loading={disableUserActions}>
                            {writingRule._id ? 'Update Writing Rule' : 'Create Writing Rule'}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default EditableWritingRulesForm;
