// Public routes
import Login from 'pages/Auth/Login';
import Signup from 'pages/Auth/Signup';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import AcceptInviteNewUser from 'pages/Auth/AcceptInviteNewUser';
import AcceptInviteExistingUser from 'pages/Auth/AcceptInviteExistingUser';
import CheckoutStatus from 'pages/Auth/CheckoutStatus';
import VerifyEmail from 'pages/Auth/VerifyEmail';

// Protected routes
import MyTemplates from 'pages/Dashboard/MyTemplates';
import AiWriter from 'pages/Dashboard/AiWriter';
import WritingRules from 'pages/Dashboard/WritingRules';
import TemplateEditor from 'pages/Dashboard/TemplateEditor';
import History from 'pages/Dashboard/History';
import Profile from 'pages/Dashboard/Profile';
import Settings from 'pages/Dashboard/Settings';
import Help from 'pages/Dashboard/Help';
import Billing from 'pages/Dashboard/Billing';
import Usage from 'pages/Dashboard/Usage';
import Theme from 'pages/Dashboard/Theme';
import Users from 'pages/Dashboard/Users';
import ForceChangePassword from 'pages/Dashboard/ForceChangePassword';
import AutoLogin from 'pages/Auth/AutoLogin';

// Public routes that can be accessed by anyone
export const publicRoutes = [...Login, ...Signup, ...ForgotPassword, ...ResetPassword, ...AcceptInviteNewUser, ...AcceptInviteExistingUser, ...CheckoutStatus, ...AutoLogin, ...VerifyEmail];

// Protected routes that can only be accessed by logged in users
export const protectedRoutes = [
    ...MyTemplates,
    ...AiWriter,
    ...WritingRules,
    ...TemplateEditor,
    ...History,
    ...Profile,
    ...Settings,
    ...Help,
    ...Billing,
    ...Usage,
    ...Theme,
    ...Users,
    ...ForceChangePassword,
];

// get routes by passing one or more tags
export const getRoutesByTags = (tags: string[]) => {
    const routes = [...publicRoutes, ...protectedRoutes];
    return routes.filter(route => tags.some(tag => route?.tags?.includes(tag)));
};

// Get the route details by passing the route id
export const getRouteById = (id: string) => {
    const routes = [...publicRoutes, ...protectedRoutes];
    return routes.find(route => route.id === id);
};

// Get route by path
export const getRouteByPath = (path: string) => {
    // Remove slash from the beginning and end of the path
    path = path.replace(/^\/|\/$/g, '');

    // Split path into segments
    const pathSegments = path.split('/');

    // Combine all routes
    const combinedRoutes = [...publicRoutes, ...protectedRoutes];

    // Find the route that matches the path
    for (const route of combinedRoutes) {
        const routePath = route.path.replace(/^\/|\/$/g, ''); // Clean the route path
        const routeSegments = routePath.split('/');

        if (routeSegments.length !== pathSegments.length) {
            continue; // If segment lengths don't match, it's not a match
        }

        let isMatch = true;
        for (let i = 0; i < routeSegments.length; i++) {
            if (routeSegments[i] !== pathSegments[i] && !routeSegments[i].startsWith(':')) {
                isMatch = false;
                break;
            }
        }

        if (isMatch) {
            return route;
        }
    }

    return undefined;
};

// Replace route params with actual values in sequence
export const replaceRouteParams = (path: string, params: any) => {
    Object.keys(params).forEach(key => {
        path = path.replace(`:${key}`, params[key]);
    });
    return path;
};
