import {apiRequest, authenticatedApiRequest, APIRequestType, APIResponse} from 'utils/api';

export const auth = {
    async login(username: string, password: string): Promise<any> {
        const response = await apiRequest(APIRequestType.POST, 'user/login', JSON.stringify({username, password}));

        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },

    async logout(refreshToken: string): Promise<boolean> {
        const response = await apiRequest(APIRequestType.POST, '/user/logout', JSON.stringify({refreshToken}));
        if (response.ok()) {
            return response.body();
        }
        throw new Error(response.getError());
    },
};
