import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import HistoryEdu from '@mui/icons-material/HistoryEdu';
import Edit from '@mui/icons-material/Edit';
import ContentCopy from '@mui/icons-material/ContentCopy';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';

interface MyTemplateCardProps {
    title: string;
    description: string;
    onWriteClick?: () => void;
    onEditClick?: () => void;
    onCopyClick?: () => void;
    onHistoryClick?: () => void;
}

function MyTemplateCard({title, description, onWriteClick = () => {}, onEditClick = () => {}, onCopyClick = () => {}, onHistoryClick = () => {}}: MyTemplateCardProps) {
    const theme = useTheme();

    return (
        <Card sx={{display: 'flex', minHeight: 200}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Typography gutterBottom variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Tooltip title="Use Template">
                        <IconButton size="large" color="primary" onClick={onWriteClick}>
                            <HistoryEdu />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Template">
                        <IconButton size="large" color="primary" onClick={onEditClick}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy Template">
                        <IconButton size="large" color="primary" onClick={onCopyClick}>
                            <ContentCopy />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="View History">
                        <IconButton size="large" color="primary" onClick={onHistoryClick}>
                            <HistoryIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Box>
        </Card>
    );
}

export default MyTemplateCard;
