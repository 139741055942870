import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {ThemeProvider, createTheme} from '@mui/material/styles';

import {AuthProvider} from 'utils/auth';
import {useState} from 'react';
import {requests} from 'requests';
import CustomDialog, {CustomDialogProps} from 'components/Dialog';
import CustomSnackbar, {CustomSnackbarProps} from 'components/Snackbar';
import {getRouteById, replaceRouteParams} from 'router/routes';
import {validateEmail} from 'utils/validations';
import {getFormattedPageTitle} from 'utils/helpers';
import LoadingButton from '@mui/lab/LoadingButton';

export default function SignIn() {
    document.title = getFormattedPageTitle('Forgot Password');
    const navigate = useNavigate();

    // Snackbar state
    const [snackBarState, setSnackBarState] = useState({
        open: false,
    } as CustomSnackbarProps);

    // Dialog state
    const [dialogState, setDialogState] = useState({
        open: false,
    } as CustomDialogProps);

    const [disableUserActions, setDisableUserActions] = useState(false);

    const [email, setEmail] = useState('');

    // State for form validation errors
    const [emailError, setEmailError] = useState('');

    // Function to validate form fields
    const handleValidateEmail = () => {
        if (!validateEmail(email)) {
            setEmailError('Invalid email.');
            return false;
        }
        setEmailError('');
        return true;
    };

    // Handler for form submission
    const handleSubmit = async () => {
        if (handleValidateEmail()) {
            try {
                setDisableUserActions(true);

                let res = await requests.user.forgotPassword(email);

                setDialogState({
                    open: true,
                    title: 'Email Sent',
                    message: 'Please check your email for a link to reset your password.',
                    button1Text: 'Close',
                    button1Action: () => {
                        setDialogState({
                            open: false,
                        });
                    },
                    button1Color: 'primary',
                    button1Variant: 'contained',
                });
            } catch (error) {
                console.log(error);
                setSnackBarState({
                    open: true,
                    message: 'Something went wrong. Please try again later.',
                    severity: 'error',
                    autoHideDuration: 5000,
                    onClose: () => {
                        setSnackBarState({...snackBarState, open: false});
                    },
                });
            } finally {
                setDisableUserActions(false);
            }
        }
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <img src={require('assets/images/logo.svg').default} alt="logo" style={{width: '150px'}} />
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}, alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h5">Forgot Password</Typography>
                    <Typography variant="body2" sx={{textAlign: 'center'}}>
                        Enter your email address and we will send you a link to reset your password.
                    </Typography>

                    <TextField
                        margin="normal"
                        fullWidth
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={!!emailError}
                        helperText={emailError}
                    />

                    <LoadingButton type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} onClick={handleSubmit} loading={disableUserActions}>
                        Continue
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                variant="body2"
                                onClick={() => {
                                    const route = getRouteById('login');
                                    if (route) {
                                        navigate(route.path);
                                    }
                                }}>
                                Go back to sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            {dialogState.open && <CustomDialog {...dialogState} />}

            {snackBarState.open && <CustomSnackbar {...snackBarState} />}
        </Container>
    );
}
