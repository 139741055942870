import {Template} from 'models/template';
import {Section} from 'models/section';
import {requests} from 'requests';
import {defaultTemplates} from './defaultTemplates';
import {defaultTemplate} from './defaultTemplates';

export const template = {
    async duplicateTemplate(templateId: string, newTemplateName?: string): Promise<Template> {
        try {
            const response = await requests.templates.get(templateId);

            const template = new Template(response);

            // 1. Fetch the sections in parallel
            const fetchedSectionsPromises = template.operations.map(item => requests.sections.get(item.op));
            const fetchedSectionsResponses = await Promise.all(fetchedSectionsPromises);

            const fetchedSections = fetchedSectionsResponses.map(sectionResponse => new Section(sectionResponse));

            // 2. Duplicate the sections
            const duplicatedSections = fetchedSections.map(item => item.copy());

            console.log(duplicatedSections);

            // 3. Create the sections in the API in parallel
            const createSectionsPromises = duplicatedSections.map(item => item.createInApi());
            await Promise.all(createSectionsPromises);

            // New template
            const newTemplate = new Template({
                name: newTemplateName ? newTemplateName : template.name + ' (copy)',
                operations: duplicatedSections.map(section => {
                    return {
                        op: section.getID(),
                    };
                }),
            });

            // Create the template in the API
            await newTemplate.createInApi();

            return newTemplate;
        } catch (e: any) {
            throw new Error(e);
        }
    },

    async createTemplate(defaultTemplate: defaultTemplate): Promise<Template> {
        try {
            // Create the sections in the API in parallel
            const createSectionsPromises = defaultTemplate.sections.map(item => item.createInApi());
            await Promise.all(createSectionsPromises);

            // New template
            const newTemplate = new Template({
                name: defaultTemplate.name,
                isExample: true,
                operations: defaultTemplate.sections.map(section => {
                    return {
                        op: section.getID(),
                    };
                }),
            });

            // Create the template in the API
            await newTemplate.createInApi();

            return newTemplate;
        } catch (e: any) {
            throw new Error(e);
        }
    },

    async createExampleTemplates(exclude = [] as string[]): Promise<void> {
        try {
            // Filter out the templates that are already created
            const templatesToCreate = defaultTemplates.filter(item => !exclude.includes(item.name));

            // Create the templates in the API in parallel
            const createTemplatesPromises = templatesToCreate.map(item => template.createTemplate(item));

            await Promise.all(createTemplatesPromises);
        } catch (e: any) {
            throw new Error(e);
        }
    },

    checkExampleTemplatesExist(exclude = [] as string[]): boolean {
        // Filter out the templates that are already created
        const templatesToCreate = defaultTemplates.filter(item => !exclude.includes(item.name));

        // Check if all templates are created
        return templatesToCreate.length === 0;
    },
};
