export class TeamMember {
    _id: any;
    username: string;
    name: string;

    constructor(data: any) {
        this._id = data._id;
        this.username = data.username;
        this.name = data.name;
    }

    getID() {
        return this._id;
    }

    setID(id: any) {
        this._id = id;
    }

    getName() {
        return this.name;
    }

    getUsername() {
        return this.username;
    }
}