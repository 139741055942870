import {useEffect} from 'react';

export interface ExternalScriptOptions {
    parent?: 'body' | 'head';
    position?: 'beginning' | 'end';
    async?: boolean;
    defer?: boolean;
}

/**
 * Custom hook to dynamically load an external script.
 *
 * @param {string} url - The source URL of the script to be loaded.
 * @param {ScriptOptions} options - Configuration options for loading the script.
 */
export const useExternalScript = (url: string, options: ExternalScriptOptions = {}) => {
    const {parent = 'body', position = 'end', async = true, defer = false} = options;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = async;
        script.defer = defer;

        const parentElement = parent === 'body' ? document.body : document.head;

        // Insert script based on the specified position
        if (position === 'beginning' && parentElement.firstChild) {
            parentElement.insertBefore(script, parentElement.firstChild);
        } else {
            parentElement.appendChild(script);
        }

        // Cleanup function to remove the script from the document
        return () => {
            if (parentElement.contains(script)) {
                parentElement.removeChild(script);
            }
        };
    }, [url, parent, position, async, defer]); // Dependencies array for the useEffect hook
};

export const useCustomScript = (inlineScript: string, options: {parent?: string; position?: string} = {}, unMountFunction?: () => void) => {
    const {
        parent = 'head', // Default parent is head
        position = 'end', // Default position is end
    } = options;

    useEffect(() => {
        // Function to create and execute the script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = inlineScript;
        const parentElement = document[parent as 'body' | 'head'];

        if (position === 'beginning' && parentElement.firstChild) {
            parentElement.insertBefore(script, parentElement.firstChild);
        } else {
            parentElement.appendChild(script);
        }

        // Cleanup function to remove the script
        return () => {
            if (parentElement.contains(script)) {
                parentElement.removeChild(script);
            }
            if (unMountFunction) {
                unMountFunction();
            }
        };
    }, [inlineScript, parent, position]); // Dependencies array
};
