import Page from './Page';
import Widgets from '@mui/icons-material/Widgets';
import SettingsIcon from '@mui/icons-material/Settings';

const routes = [
    {
        id: 'settings',
        name: 'Settings',
        path: '/settings',
        showInSidebar: false,
        pageSidebarPosition: 2,
        icon: SettingsIcon,
        Component: Page,
        // tags: ['user-config'],
    },
];

export default routes;
