import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import {WritingRule} from 'models/writingRule';
import * as React from 'react';
import {useEffect} from 'react';
import {requests} from 'requests';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

interface ApplyWritingRuleProps {
    handleApplyWritingRule: (rule: WritingRule) => void;
    onError?: (error: Error) => void;
}

const ApplyWritingRule: React.FC<ApplyWritingRuleProps> = ({handleApplyWritingRule, onError}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const [writingRules, setWritingRules] = React.useState([] as WritingRule[]);
    const [selectedRule, setSelectedRule] = React.useState(new WritingRule({}) as WritingRule);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null as string | null);

    const handleSelect = (rule: WritingRule) => {
        setSelectedRule(rule);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    async function fetchWritingRules() {
        try {
            let res = await requests.writingRules.list();
            setWritingRules(res.map((rule: any) => new WritingRule(rule)));
            setIsLoading(false);
        } catch (error) {
            setError('Error fetching templates. Please try again later.');
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchWritingRules();
    }, []);

    return (
        <>
            <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" size='small' sx={{width: '100%'}}>
                {isLoading && <Button disabled>Loading...</Button>}
                {!isLoading && (
                    <Button onClick={() => handleApplyWritingRule(selectedRule)} sx={{width: '100%'}}>
                        {writingRules.length === 0 ? 'No Writing Rules' : selectedRule._id ? 'Apply Writing Rule' : 'Select Writing Rule'}
                    </Button>
                )}
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="Select writing rule"
                    aria-haspopup="menu"
                    onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                    marginBottom: '10px !important'
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {writingRules.map(rule => (
                                        <MenuItem key={rule._id} onClick={() => handleSelect(rule)} selected={rule._id === selectedRule._id}>
                                            {rule.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default ApplyWritingRule;
